.group {
   padding: 80px 0;
   overflow: hidden;

   &.group__1 {
      h1 {
         margin-bottom: 50px;
      }
      ul {
         @include grid(1,1,1,2,2,20px);
         margin: 0;
         padding: 0;

         li {
            list-style: none;
            display: block;
            padding: 20px;
            padding-left: 140px;
            box-shadow: 0 2px 24px 0 rgba(0,0,0,0.07);
            border-radius: 8px;
            background: $white;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            min-width: 0;

            h2 {
               font-size: 1.6rem;
               margin: 0;
            }

            .image {
               position: absolute;
               left: 8px;
               top: 0;
               bottom: 0;
               height: 100%;
               width: 120px;
               background-size: cover;
               background-position: center;
            }

            .border {
               position: absolute;
               left: 0px;
               top: 0;
               bottom: 0;
               height: 100%;
               width: 8px;
               background: $blue;
            }

            .variations {
               transform: translateY(-100%);
               opacity: 0;
               visibility: hidden;
               transition: all 350ms ease;
               position: absolute;
               right: 15px;
               top: 50%;
               transition-delay: 300ms;

               a {
                  border: 2px solid $blue;
                  padding: 10px 15px;
                  margin-right: 10px;
                  box-sizing: border-box;
                  border-radius: 100px;
                  text-decoration: none;
                  color: $blue;
                  transition: all 350ms ease;

                  &:hover {
                     background: $blue;
                     color: $white;
                  }
               }
            }
            &:nth-child(2n + 2) {
               .border {
                  background: $orange;
               }

               h2 {
                  color: $orange;
               }

               .variations {
                  a {
                     color: $orange;
                     border-color: $orange;

                     &:hover {
                        background: $orange;
                        color: $white;
                     }
                  }
               }
            }

            &:nth-child(3n + 1) {
               .border {
                  background: $yellow;
               }

               h2 {
                  color: $yellow;
               }

               .variations {
                  a {
                     color: $yellow;
                     border-color: $yellow;

                     &:hover {
                        background: $yellow;
                        color: $white;
                     }
                  }
               }
            }

            &:nth-child(4n + 2) {
               .border {
                  background: $green;
               }

               h2 {
                  color: $green;
               }

               .variations {
                  a {
                     color: $green;
                     border-color: $green;

                     &:hover {
                        background: $green;
                        color: $white;
                     }
                  }
               }
            }



            @include respond-above(md) {
               &:hover {
                  .variations {
                     transition-delay: 0ms;
                     transform: translateY(-50%);
                     opacity: 1;
                     visibility: visible;
                  }
               }
            }

            @include respond-below(md) {
               height: 100%;

               span {
                  white-space: normal;
               }

               .variations {
                  transform: translateY(0%);
                  opacity: 1;
                  display: block;
                  box-sizing: border-box;
                  margin-bottom: 40px;
                  visibility: visible;
                  position: relative;
                  margin-left: 15px;
                  top: 30px;
               }
            }
         }
      }
   }

   &.group__2 {
      position: relative;
      padding: 100px 0 140px 0;
      background-image: url('../images/tools_background_lighter.png');
      background-size: auto 100%;
      background-position: center bottom;

      &:after {
         content: " ";
         width: 100%;
         height: 2px;
         background: $blue;
         position: absolute;
         background: linear-gradient(90deg, $blue 0%, $green 31.11%, $yellow 58.25%, $orange 100%);
         top: 255px;
         z-index: 1;
         display: block;
         left: 0;
         right: 0;
      }

      .slider-btn {
         background: $blue-dark;
         display: inline-block;
         width: 60px;
         height: 60px;
         border-radius: 100px;
         position: absolute;
         top: -80px;
         right: 20px;
         z-index: 3;
         text-align: center;
         cursor: pointer;
         border: 2px solid $blue-dark;
         transition: all 350ms ease;

         &.prev-slider {
            right: 100px;
         }

         span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            font-weight: bold;

            &:before {
               color: $white;
               transition: all 350ms ease;
            }
         }

         &:hover {
            background: none;

            span {
               &:before {
                  color: $blue-dark;
               }
            }
         }

         @include respond-below(sm){
            top: -80px;
            left: 80px;
            margin-bottom: 100px;

            &.prev-slider {
               left: 0px;
            }
         }

         &.slick-disabled {
            opacity: 0.1;
            pointer-events: none;
            cursor: default;
         }
      }

      @include respond-below(sm){
         padding-top: 150px;

         .slick-list {
            margin-top: 100px !important;
         }

         h1 {
            margin-top: -120px;
         }
      }

      ul {
         z-index: 3;
         position: relative;
         margin-top: 20px;

         .slick-slide {
            margin-left:20px;
         }

         .slick-list {
            margin-left:-60px;
         }
         li {
            list-style: none;
            display: inline-block;
            text-align: center;

            .group-block {
               width: 70px;
               height: 70px;
               border-radius: 6px;
               background: $blue;
               display: block;
               position: relative;
               margin: 20px auto;

               span {
                  position: absolute;
                  left: 0;
                  right: 0;
                  text-align: center;
                  top: 50%;
                  transform: translateY(-50%);
                  color: $white;
               }

               &:after {
                  content: " ";
                  width: 1px;
                  height: 22px;
                  background: $blue;
                  bottom: -20px;
                  display: block;
                  position: absolute;
                  margin: 0 auto;
                  left: 0;
                  right: 0;
               }
            }

            .image {
               width: 100%;
               border-radius: 11px;
               display: block;
               background-color: $grey-lighter;
               text-align: center;
               height: 200px;
               position: relative;
               background-size: cover;
               background-position: center;
               cursor: pointer;

               .variations {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  right: 0;
                  transform: translateY(-100%);
                  opacity: 0;
                  visibility: hidden;
                  transition: all 350ms ease;

                  a {
                     text-decoration: none;
                     border: 2px solid $blue;
                     border-radius: 100px;
                     padding: 10px 15px;
                     margin-right: 10px;
                     color: $blue;
                     transition: all 350ms ease;

                     &:hover {
                        background: $blue;
                        color: $white;
                     }
                  }
               }

               @include respond-above(md){
                  &:hover {
                     .variations {
                        transform: translateY(-50%);
                        opacity: 1;
                        visibility: visible;
                     }
                  }
               }

               @include respond-below(md){
                  .variations {
                     transform: translateY(-50%);
                     opacity: 1;
                     visibility: visible;
                  }
               }
            }

            &:nth-child(2n + 2) {
               .group-block {
                  background: $orange;

                  &::after {
                     background: $orange;
                  }
               }

               .variations {
                  a {
                     color: $orange;
                     border-color: $orange;

                     &:hover {
                        background: $orange;
                        color: $white;
                     }
                  }
               }
            }

            &:nth-child(3n + 3) {
               .group-block {
                  background: $yellow;

                  &::after {
                     background: $yellow;
                  }
               }

               .variations {
                  a {
                     color: $yellow;
                     border-color: $yellow;

                     &:hover {
                        background: $yellow;
                        color: $white;
                     }
                  }
               }
            }

            &:nth-child(4n + 4) {
               .group-block {
                  background: $green;

                  &::after {
                     background: $green;
                  }
               }

               .variations {
                  a {
                     color: $green;
                     border-color: $green;

                     &:hover {
                        background: $green;
                        color: $white;
                     }
                  }
               }
            }
         }
      }

      &.dark {
         background: $blue-dark;

         h1 {
            color: $white;
         }

         .slider-btn {
            background: $white;
            border-color: $white;

            span {
               &:before {
                  color: $blue-dark;
               }
            }
         }
      }
   }

   &.group__3 {
      background: $blue-dark;
      padding-bottom: 120px;

      h1 {
         margin-bottom: 40px;
         color: $white;
      }

      ul {
         @include grid(1,2,3,4,4,0px);
         padding: 0;
         margin: 0;

         li {
            list-style: none;
            height: 200px;
            background-size: cover;
            position: relative;
            background-size: cover;
            background-position: center;
            text-align: center;
            cursor: pointer;

            &:after {
               content: " ";
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               bottom: 0;
               width: 100%;
               height: 100%;
               background: linear-gradient(180deg, rgba(29,44,53,0.12) 0.06%, #1D2C35 95.87%);
               z-index: 1;
            }

            .content {
               position: absolute;
               z-index: 2;
               color: $white;
               bottom: 15px;
               left: 15px;
               width: calc(100% - 30px);

               b {
                  color: $blue;
                  margin-bottom: -10px;
                  display: block;
               }

               span {
                  display: block;
                  margin-left: 15px;
               }
            }

            .variations {
               position: absolute;
               top: 50%;
               left: 0;
               right: 0;
               transform: translateY(-100%);
               opacity: 0;
               visibility: hidden;
               transition: all 350ms ease;
               z-index: 3;

               a {
                  text-decoration: none;
                  border: 2px solid $white;
                  border-radius: 100px;
                  padding: 10px 15px;
                  margin-right: 10px;
                  color: $white;
                  transition: all 350ms ease;

                  &:hover {
                     background: $white;
                     color: $blue;
                  }
               }
            }

            @include respond-above(md){
               &:hover {
                  .variations {
                     transform: translateY(-60%);
                     opacity: 1;
                     visibility: visible;
                  }
               }
            }

            @include respond-below(md){
               .variations {
                  transform: translateY(-50%);
                  opacity: 1;
                  visibility: visible;
               }
            }

            &:nth-child(2n + 2) {
               .content {
                  b {
                     color: $green;
                  }
               }
            }

            &:nth-child(3n + 3) {
               .content {
                  b {
                     color: $orange;
                  }
               }
            }

            &:nth-child(4n + 4) {
               .content {
                  b {
                     color: $yellow;
                  }
               }
            }
         }
      }
   }
}

.group-detail {
   position: relative;

   .group-picture {
      width: 100%;
      max-width: 100%;
      margin: 25px auto;
      position: relative;
   }
}

.teachers {
   margin-bottom: 40px;
   display: block;
   @include grid(1,2,2,3,3);

   .teacher {
      display: inline-block;
      background: $off-white;
      border-radius: 200px;
      min-width: 0;

      .avatar {
         background-size: cover;
         background-position: center;
         width: 120px;
         height: 120px;
         border-radius: 100%;
         display: inline-block;
         vertical-align: middle;
         margin-right: 5px;
      }

      h4 {
         display: inline-block;
         vertical-align: middle;
         padding-right: 10px;
         width: calc(100% - 130px);
         height: calc(100% - 50px);
      }

      span {
         color: $grey;
         font-size: 0.875rem;
         width: 100%;
         display: inline-block;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
      }
   }
}