.qoute {
    .wrapper {
        text-align: center;

        span {
            font-size: 2rem;
            @include themify($themes) {
                color: themed('primary-color');
            }
            display: inline-block;
        }   

        p {
            font-size: 2rem;
            line-height: 1;
            @include themify($themes) {
                color: themed('primary-color');
            }
            display: inline-block;
        }

        .name {
            @include themify($themes) {
                color: themed('primary-color');
            }
        }
    }

    &.dark {
        @include themify($themes) {
            background: themed('primary-color');
        }

        .wrapper {
            span, p, .name {
                color: $white;
            }
        }
    }
}