@charset "UTF-8";
/*
 * Author: Niels Buijtenhuis
 *
 * SCSS structure:
 * 0 - Vendor — Optional third party imports
 * 1 - Settings — preprocessors, fonts, variables
 * 2 - Tools — mixins, functions
 * 3 - Base — reset / normalize
 * 4 - Elements — HTML elements
 * 5 - Components — UI components
 * 6 - Helpers - helper classes
 */
/* 
 * Mixins
 */ /* 
 * All colors used on the website.
 */
/* 
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");
/*
 * Grid, Breakpoints & Widths
 */
/* 
 * Form field base
 */
textarea, input[type=date], input[type=text], input[type=email], input[type=url], input[type=search], input[type=password], input[type=tel] {
  width: 100%;
  max-width: 400px;
  padding: 0.4em 0.4em;
  border: 1px solid #787878;
  background: #fff;
  color: #787878;
  outline: 0;
  border-radius: 5px;
  font-size: 0.9rem;
  padding: 24px 12px !important;
  max-width: 500px !important;
}
textarea:focus, input[type=date]:focus, input[type=text]:focus, input[type=email]:focus, input[type=url]:focus, input[type=search]:focus, input[type=password]:focus, input[type=tel]:focus {
  border-color: #000000;
  background: #fff;
}

select {
  width: 100%;
  max-width: 400px;
  background: #fff url("../images/down-arrow.svg") no-repeat right 15px center;
  background-size: 15px 15px;
  appearance: none;
  border: 2px solid #000000;
  outline: 0;
  padding: 0.8em 1em;
  line-height: normal;
  border-radius: 5px;
  font-size: 1.125rem;
}
select:focus {
  background: #fff url(../images/up-arrow.svg);
  border-color: #000000;
}

/* 
 * Button base
 */
/* 
 * Mixin for Media Queries
 */
/* 
 * Grid mixin
 */
/* 
 * Reset HTML elements and layout
 */
::selection {
  color: #fff;
  background: #000000;
}

*,
input[type=search] {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 15px;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 16px;
  }
}

body {
  background: #fff;
  color: #111111;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

a {
  color: #000000;
  transition: color 0.3s ease-in-out;
}
a:hover {
  color: #252932;
  text-decoration: none;
}

li {
  font-size: 1.25rem;
  line-height: 1.8;
}

p {
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 1.25rem;
  line-height: 1.8;
}
p:last-child {
  margin-bottom: 0;
}

b,
strong {
  font-weight: 700;
}

embed,
img,
object,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

iframe {
  display: block;
  max-width: 100%;
}

@media (max-width: 991px) {
  table {
    display: block;
    overflow: scroll;
    width: 100% !important;
  }
  table td {
    min-width: 200px;
    width: auto !important;
  }
}

/* 
 * Layout base
 */
body {
  width: 100%;
  overflow-x: hidden;
}
body.fixed {
  max-height: 100vh;
  overflow: hidden;
}

.layout-container {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  margin: 0;
  transition: transform 400ms;
}
@media (max-width: 991px) {
  .layout-container.slide-canvas {
    position: fixed;
    transform: translate(400px);
  }
}
@media (max-width: 767px) {
  .layout-container.slide-canvas {
    transform: translate(calc(100% - 60px));
  }
}

section {
  position: relative;
  padding: 40px 0;
  overflow: hidden;
}
section.pop-up {
  z-index: 999999 !important;
}
@media (max-width: 767px) {
  section {
    padding: 20px 0;
  }
}

.wrapper {
  width: 100%;
  max-width: 1260px;
  padding: 0 40px;
  margin: 0 auto;
  position: relative;
  display: block;
}
@media (max-width: 1279px) {
  .wrapper {
    padding: 0 40px;
    max-width: 1100px;
  }
}
@media (max-width: 991px) {
  .wrapper {
    padding: 0 40px;
    max-width: 900px;
  }
}
@media (max-width: 767px) {
  .wrapper {
    padding: 0 20px;
  }
}

#top-bar {
  background: #1D2C35;
  height: 50px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 102;
  position: fixed;
  width: 100%;
}
#top-bar a {
  color: #fff;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0 10px;
  display: inline-flex;
  align-items: center;
}
#top-bar a span.button {
  margin-right: 10px;
  margin-left: 20px;
}
#top-bar a span.button::before {
  color: #fff;
  font-size: 14px;
}
#top-bar a span.button.icon-Mail::before {
  font-size: 10px;
}
.theme-color-1 #top-bar a span.button {
  background: #006EB7;
}
.theme-color-2 #top-bar a span.button {
  background: #FBBA00;
}
.theme-color-3 #top-bar a span.button {
  background: #E84E0F;
}
.theme-color-4 #top-bar a span.button {
  background: #95C11F;
}
.theme-color-1 #top-bar a:hover {
  color: #FBBA00;
}
.theme-color-1 #top-bar a:hover span.button {
  background: #FBBA00;
}
.theme-color-2 #top-bar a:hover {
  color: #006EB7;
}
.theme-color-2 #top-bar a:hover span.button {
  background: #006EB7;
}
.theme-color-3 #top-bar a:hover {
  color: #95C11F;
}
.theme-color-3 #top-bar a:hover span.button {
  background: #95C11F;
}
.theme-color-4 #top-bar a:hover {
  color: #006EB7;
}
.theme-color-4 #top-bar a:hover span.button {
  background: #006EB7;
}
#top-bar.on-top {
  z-index: 500;
}
@media (max-width: 767px) {
  #top-bar {
    z-index: 99999;
    justify-content: center;
  }
  #top-bar a span {
    display: none;
  }
  #top-bar a span.button {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
}

#navigation {
  position: fixed;
  top: 70px;
  width: 100%;
  padding: 10px 40px;
  display: flex;
  justify-content: flex-end;
  z-index: 102;
  transition: all 400ms;
}
#navigation #logo {
  position: absolute;
  transform: translateY(-50%);
  left: 40px;
  z-index: 0;
}
#navigation #logo img {
  max-width: 260px;
  max-height: 155px;
  position: absolute;
  transition: opacity 400ms;
}
#navigation #logo img.color {
  opacity: 0;
}
.fixed #navigation #logo img {
  height: 65px;
  max-width: none;
}
@media (max-width: 767px) {
  .fixed #navigation #logo img {
    height: 45px;
  }
}
#navigation #logo.stev::before {
  content: "";
  position: absolute;
  top: -265px;
  left: -250px;
  width: 529px;
  height: 513px;
  background: url(../images/stev_mark.png) no-repeat;
  background-size: 529px 513;
  z-index: -1;
  opacity: 0.5;
}
#navigation #logo.pill::before {
  content: "";
  position: absolute;
  top: -280px;
  left: -260px;
  border-radius: 200px;
  width: 600px;
  height: 400px;
  transform: rotate(45deg);
  z-index: -1;
  opacity: 0.65;
}
.theme-color-1 #navigation #logo.pill::before {
  background: #006EB7;
}
.theme-color-2 #navigation #logo.pill::before {
  background: #FBBA00;
}
.theme-color-3 #navigation #logo.pill::before {
  background: #E84E0F;
}
.theme-color-4 #navigation #logo.pill::before {
  background: #95C11F;
}
@media (max-width: 767px) {
  #navigation #logo.pill::before {
    display: none;
  }
}
#navigation.fixed #logo img {
  max-width: 200px;
}
#navigation.fixed #logo.stev::before, #navigation.fixed #logo.pill::before {
  opacity: 0;
}
#navigation.on-top #logo::before {
  opacity: 0;
  transition: opacity 400ms;
}
#navigation ul li {
  display: inline-block;
  margin-right: 20px;
}
#navigation ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.125rem;
}
@media (max-width: 767px) {
  .layout-container #navigation ul {
    display: none !important;
  }
}
#navigation a {
  margin-left: 20px;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 700;
}
#navigation a span::before {
  color: #fff;
  font-size: 1.5rem;
}
#navigation a.hamburger {
  background: #fff;
  position: relative;
}
#navigation a.hamburger span {
  transition: all 400ms;
  height: 2px;
  width: 24px;
  position: absolute;
}
.theme-color-1 #navigation a.hamburger span {
  background: #006EB7;
}
.theme-color-2 #navigation a.hamburger span {
  background: #FBBA00;
}
.theme-color-3 #navigation a.hamburger span {
  background: #E84E0F;
}
.theme-color-4 #navigation a.hamburger span {
  background: #95C11F;
}
#navigation a.hamburger span:first-child {
  transform: translateY(-6px);
}
#navigation a.hamburger span:last-child {
  transform: translateY(6px);
}
#navigation a.hamburger.active span {
  opacity: 0;
  transform-origin: center center;
}
#navigation a.hamburger.active span:first-child {
  transform: translateY(0) rotate(45deg);
  opacity: 1;
}
#navigation a.hamburger.active span:last-child {
  transform: translateY(0) rotate(-45deg);
  opacity: 1;
}
.theme-color-1 #navigation a.route {
  background: #006EB7;
}
.theme-color-2 #navigation a.route {
  background: #FBBA00;
}
.theme-color-3 #navigation a.route {
  background: #E84E0F;
}
.theme-color-4 #navigation a.route {
  background: #95C11F;
}
@media (max-width: 767px) {
  #navigation a.route {
    width: 45px;
    height: 45px;
  }
}
#navigation a.to-home {
  display: inline-block !important;
  margin-left: 0;
}
#navigation.on-top {
  z-index: 350;
}
#navigation.on-top a.route {
  opacity: 0;
}
#navigation.fixed.nav_2:not(.on-top), #navigation.fixed.nav_1 {
  background: #fff;
  top: 50px;
}
#navigation.fixed.nav_2:not(.on-top) #logo img, #navigation.fixed.nav_1 #logo img {
  opacity: 0;
}
#navigation.fixed.nav_2:not(.on-top) #logo img.color, #navigation.fixed.nav_1 #logo img.color {
  opacity: 1;
  transition: opacity 1200ms;
}
.theme-color-1 #navigation.fixed.nav_2:not(.on-top) .hamburger, .theme-color-1 #navigation.fixed.nav_1 .hamburger {
  background: #006EB7;
}
.theme-color-2 #navigation.fixed.nav_2:not(.on-top) .hamburger, .theme-color-2 #navigation.fixed.nav_1 .hamburger {
  background: #FBBA00;
}
.theme-color-3 #navigation.fixed.nav_2:not(.on-top) .hamburger, .theme-color-3 #navigation.fixed.nav_1 .hamburger {
  background: #E84E0F;
}
.theme-color-4 #navigation.fixed.nav_2:not(.on-top) .hamburger, .theme-color-4 #navigation.fixed.nav_1 .hamburger {
  background: #95C11F;
}
#navigation.fixed.nav_2:not(.on-top) .hamburger span, #navigation.fixed.nav_1 .hamburger span {
  background: #fff !important;
}
.theme-color-1 #navigation.fixed.nav_2:not(.on-top) ul li a, .theme-color-1 #navigation.fixed.nav_1 ul li a {
  color: #006EB7;
}
.theme-color-2 #navigation.fixed.nav_2:not(.on-top) ul li a, .theme-color-2 #navigation.fixed.nav_1 ul li a {
  color: #FBBA00;
}
.theme-color-3 #navigation.fixed.nav_2:not(.on-top) ul li a, .theme-color-3 #navigation.fixed.nav_1 ul li a {
  color: #E84E0F;
}
.theme-color-4 #navigation.fixed.nav_2:not(.on-top) ul li a, .theme-color-4 #navigation.fixed.nav_1 ul li a {
  color: #95C11F;
}
#navigation.nav_1.on-top a.hamburger {
  margin-right: 300px;
}
@media (max-width: 991px) {
  #navigation {
    top: 50px;
    padding: 10px 20px;
  }
  #navigation #logo {
    left: 20px;
  }
  #navigation #logo.stev::before {
    display: none;
  }
}
@media (max-width: 767px) {
  #navigation {
    padding: 10;
    z-index: 99999;
  }
  #navigation #logo {
    left: 10px;
  }
  #navigation #logo img {
    height: 45px;
  }
  #navigation a.hamburger.big {
    width: 45px;
    height: 45px;
  }
}

.off-canvas {
  position: fixed;
  z-index: -10;
  opacity: 0;
  transition: all 400ms;
}
.off-canvas .search {
  border-radius: 25px !important;
  max-width: 300px !important;
  border: 0;
  padding: 12px 20px !important;
  padding-left: 40px !important;
  position: relative;
  background: url(../images/search.png) #fff no-repeat !important;
  background-size: 22px !important;
  background-position: 12px 10px !important;
}
.off-canvas .maps {
  overflow: hidden;
  display: inline-block;
  border-radius: 10px;
}
.off-canvas .maps iframe {
  display: block;
  max-width: 100%;
}
.off-canvas.active {
  z-index: 300;
  opacity: 1;
}
.off-canvas .info {
  display: block;
  width: 100%;
  margin-top: 20px;
  color: #fff;
}
@media (max-width: 767px) {
  .off-canvas .info {
    padding-bottom: 40px;
  }
}
.off-canvas .info a {
  color: #fff;
  width: 100%;
  display: block;
  padding-top: 15px;
  text-decoration: none;
  font-size: 0.875rem;
}
.off-canvas .info a span::before {
  color: #fff;
  font-size: 0.6rem;
  margin-right: 5px;
}
.off-canvas .info a.button {
  font-size: 1rem;
  margin-top: 20px;
  display: inline-block;
  width: auto;
}
.off-canvas ul li.dropdown i {
  font-style: normal;
  cursor: pointer;
}
.off-canvas ul li.dropdown i::after {
  font-size: 0.7rem;
  margin-left: 20px;
  color: #fff;
  content: "\e900";
  font-family: "icomoon";
  transform: translateY(-1px);
  display: inline-block;
}
.off-canvas ul li.dropdown > ul {
  display: none;
  padding: 0;
  overflow: hidden;
}
.off-canvas ul li.dropdown > ul li a {
  padding-left: 20px;
  font-size: 1.125rem;
}
.off-canvas ul li.dropdown.open i::after {
  content: "\e904";
}
.theme-color-1 .off-canvas ul li.active > a {
  color: #FBBA00 !important;
}
.theme-color-2 .off-canvas ul li.active > a {
  color: #006EB7 !important;
}
.theme-color-3 .off-canvas ul li.active > a {
  color: #95C11F !important;
}
.theme-color-4 .off-canvas ul li.active > a {
  color: #006EB7 !important;
}
.off-canvas.nav_1 {
  opacity: 1;
  z-index: 300;
  background: #fff;
  max-width: 320px;
  height: calc(100vh - 50px);
  overflow: scroll;
  right: 0;
  top: 50px;
  transform: translateX(100%);
  padding: 20px;
  padding-top: 40px;
}
.off-canvas.nav_1 .socials {
  display: none;
}
.off-canvas.nav_1 .wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.off-canvas.nav_1 .wrapper .right {
  margin-top: auto;
}
.off-canvas.nav_1 .wrapper .info {
  color: #000000;
}
.off-canvas.nav_1 .wrapper .info a {
  color: #000000;
}
.off-canvas.nav_1 .wrapper .info a span::before {
  color: #000000;
}
.off-canvas.nav_1 .wrapper .info a.button {
  color: #fff;
}
.off-canvas.nav_1 .maps {
  max-width: 100%;
  height: 200px;
}
.off-canvas.nav_1 h3 {
  color: #000000;
  font-size: 2rem;
  margin-bottom: 10px;
  margin-top: 20px;
}
.off-canvas.nav_1 ul li {
  padding: 5px 0;
}
.off-canvas.nav_1 ul li a {
  text-decoration: none;
}
.off-canvas.nav_1 ul li.dropdown {
  position: relative;
}
.off-canvas.nav_1 ul li.dropdown i {
  position: absolute;
  right: -30px;
}
.off-canvas.nav_1 ul li.dropdown i::after {
  color: #000000;
}
.off-canvas.nav_1.active {
  transform: translateX(0);
  z-index: 400;
}
.off-canvas.nav_2 {
  width: 100vw;
  height: calc(100vh - 50px);
  top: 50px;
  padding-top: 110px;
}
.theme-color-1 .off-canvas.nav_2 {
  background: #006EB7;
}
.theme-color-2 .off-canvas.nav_2 {
  background: #FBBA00;
}
.theme-color-3 .off-canvas.nav_2 {
  background: #E84E0F;
}
.theme-color-4 .off-canvas.nav_2 {
  background: #95C11F;
}
.off-canvas.nav_2 .socials {
  position: absolute;
  left: 30px;
  bottom: 30px;
}
.off-canvas.nav_2 .socials a {
  text-decoration: none;
  margin-right: 10px;
}
.off-canvas.nav_2 .socials a span::before {
  color: #fff;
  font-size: 1.25rem;
}
@media (max-width: 991px) {
  .off-canvas.nav_2 .socials {
    position: relative;
    bottom: auto;
  }
}
@media (max-width: 767px) {
  .off-canvas.nav_2 .socials {
    display: none;
  }
}
.off-canvas.nav_2 .wrapper {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
  height: 100%;
  overflow: auto;
}
.off-canvas.nav_2 .wrapper > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .off-canvas.nav_2 .wrapper > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .off-canvas.nav_2 .wrapper > * {
    width: calc((100% / 1) - (20px * (1 - 1) / 1));
  }
  .off-canvas.nav_2 .wrapper > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .off-canvas.nav_2 .wrapper > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .off-canvas.nav_2 .wrapper > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .off-canvas.nav_2 .wrapper > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .off-canvas.nav_2 .wrapper > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .off-canvas.nav_2 .wrapper > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .off-canvas.nav_2 .wrapper > *:nth-child(2n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .off-canvas.nav_2 .wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    .off-canvas.nav_2 .wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 768px) {
    .off-canvas.nav_2 .wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .off-canvas.nav_2 .wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .off-canvas.nav_2 .wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .off-canvas.nav_2 .wrapper > * {
    width: 100%;
    margin: 0;
  }
}
.off-canvas.nav_2 .wrapper h3 {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 10px;
  margin-top: 20px;
}
.off-canvas.nav_2 .wrapper .left {
  padding: 40px 0;
  height: 100%;
}
@media (max-width: 767px) {
  .off-canvas.nav_2 .wrapper .left {
    overflow: unset;
    padding-bottom: 0;
  }
}
.off-canvas.nav_2 .wrapper .right {
  padding-bottom: 40px;
}
@media (max-width: 767px) {
  .off-canvas.nav_2 .wrapper .right {
    padding-bottom: 40px;
  }
  .off-canvas.nav_2 .wrapper .right .maps {
    display: none;
  }
}
.off-canvas.nav_2 .wrapper ul li {
  padding: 5px 0;
}
.off-canvas.nav_2 .wrapper ul li a {
  text-decoration: none;
  color: #fff;
}
.off-canvas.nav_2::after {
  content: "";
  background: url(../images/stev_mark.png) no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 390px;
  height: 360px;
  opacity: 0.75;
  z-index: -1;
}
@media (max-width: 767px) {
  .off-canvas.nav_2::after {
    display: none;
  }
}

#breadcrumbs {
  padding: 5px 0;
  background: #1D2C35;
}
#breadcrumbs ul li {
  display: inline-block;
}
#breadcrumbs ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}
#breadcrumbs ul li span {
  margin: 0 10px;
}
#breadcrumbs ul li span::before {
  font-size: 0.75rem;
}
.theme-color-1 #breadcrumbs ul li span::before {
  color: #FBBA00;
}
.theme-color-2 #breadcrumbs ul li span::before {
  color: #006EB7;
}
.theme-color-3 #breadcrumbs ul li span::before {
  color: #95C11F;
}
.theme-color-4 #breadcrumbs ul li span::before {
  color: #006EB7;
}

.hero {
  background-size: cover;
  background-position: center;
  position: relative;
  height: 550px;
  padding: 0 20px;
}
.hero .wrapper {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.hero .wrapper h1 {
  padding: 0;
  margin: 0;
  color: #fff;
  z-index: 200;
  width: 100%;
}
.hero .wrapper h2 {
  padding: 0;
  margin: 0;
  color: #fff;
  z-index: 200;
  width: 100%;
}
@media (max-width: 767px) {
  .hero {
    height: 280px;
    padding: 0 0 20px 0;
  }
  .hero .wrapper {
    justify-content: flex-end;
  }
  .hero .wrapper h1 {
    font-size: 2rem;
  }
  .hero .wrapper h2 {
    font-size: 1.5rem;
  }
}

#footer {
  background: #1D2C35;
  margin-top: auto;
}
#footer ul {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
  padding: 20px 0 40px 0 !important;
}
#footer ul > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  #footer ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  #footer ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  #footer ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #footer ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  #footer ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  #footer ul > * {
    width: calc((100% / 4) - (20px * (4 - 1) / 4));
  }
  #footer ul > *:nth-child(4n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  #footer ul > * {
    width: calc((100% / 4) - (20px * (4 - 1) / 4));
  }
  #footer ul > *:nth-child(4n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  #footer ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    #footer ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 768px) {
    #footer ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    #footer ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (min-width: 1280px) {
    #footer ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  #footer ul > * {
    width: 100%;
    margin: 0;
  }
}
#footer ul li p {
  color: #fff;
  font-size: 1rem;
}
#footer ul li ul {
  display: block;
  padding: 0 !important;
}
#footer ul li ul li {
  width: 100%;
  line-height: 1.4;
  display: block;
}
#footer ul li ul li a {
  font-size: 1rem;
  margin-right: 0;
}
#footer ul li h4 {
  color: #fff;
  margin-bottom: 10px;
}
#footer ul li a {
  text-decoration: none;
  margin-right: 20px;
  color: #fff;
}
#footer ul li a span:before {
  color: #fff;
}
.theme-color-1 #footer ul li a:hover {
  color: #FBBA00;
}
.theme-color-2 #footer ul li a:hover {
  color: #006EB7;
}
.theme-color-3 #footer ul li a:hover {
  color: #95C11F;
}
.theme-color-4 #footer ul li a:hover {
  color: #006EB7;
}
#footer .credits {
  background: #1B1E24;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 0.875rem;
  font-weight: 700;
}

/* 
 * Form fields
 */
.wpforms-confirmation-container-full {
  background: transparent !important;
  border: 1px solid #fff !important;
}

/* 
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  line-height: 1.5;
}
.theme-color-1 h1, .theme-color-1 h2, .theme-color-1 h3, .theme-color-1 h4, .theme-color-1 h5, .theme-color-1 h6 {
  color: #006EB7;
}
.theme-color-2 h1, .theme-color-2 h2, .theme-color-2 h3, .theme-color-2 h4, .theme-color-2 h5, .theme-color-2 h6 {
  color: #FBBA00;
}
.theme-color-3 h1, .theme-color-3 h2, .theme-color-3 h3, .theme-color-3 h4, .theme-color-3 h5, .theme-color-3 h6 {
  color: #E84E0F;
}
.theme-color-4 h1, .theme-color-4 h2, .theme-color-4 h3, .theme-color-4 h4, .theme-color-4 h5, .theme-color-4 h6 {
  color: #95C11F;
}

h1 {
  font-size: 3rem;
  margin-bottom: 0;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

/* 
 * Media
 */
/* 
 * Buttons
 */
.button {
  display: inline-block;
  vertical-align: middle;
  padding: 10px 20px;
  border: 2px solid transparent;
  border-radius: 5px;
  -webkit-appearance: none;
  font-weight: 600;
  font-size: 1.25rem;
  cursor: pointer;
  transition-property: background-color, border-color, color;
  transition-duration: 0.3s;
  outline: 0;
  text-align: center;
  min-width: auto;
  white-space: nowrap;
  text-decoration: none !important;
  line-height: initial;
  position: relative;
  background-color: #000000;
  border-color: #000000;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.button:hover, .button:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.button:focus {
  background-color: black;
  border-color: black;
}
.theme-color-1 .button {
  background: #FBBA00;
  color: #fff;
  border: #FBBA00;
}
.theme-color-1 .button:hover {
  background: #c89400;
}
.theme-color-2 .button {
  background: #006EB7;
  color: #fff;
  border: #006EB7;
}
.theme-color-2 .button:hover {
  background: #004f84;
}
.theme-color-3 .button {
  background: #95C11F;
  color: #fff;
  border: #95C11F;
}
.theme-color-3 .button:hover {
  background: #739518;
}
.theme-color-4 .button {
  background: #006EB7;
  color: #fff;
  border: #006EB7;
}
.theme-color-4 .button:hover {
  background: #004f84;
}
.button.round {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 0;
}
.button.round.small {
  width: 30px;
  height: 30px;
}
.button.round.big {
  width: 65px;
  height: 65px;
}

.wpforms-submit {
  border-radius: 4px !important;
}
.theme-color-1 .wpforms-submit {
  background: #FBBA00;
  color: #fff;
  border: #FBBA00;
}
.theme-color-2 .wpforms-submit {
  background: #006EB7;
  color: #fff;
  border: #006EB7;
}
.theme-color-3 .wpforms-submit {
  background: #95C11F;
  color: #fff;
  border: #95C11F;
}
.theme-color-4 .wpforms-submit {
  background: #006EB7;
  color: #fff;
  border: #006EB7;
}

.button-group li {
  border-radius: 100%;
  padding: 10px;
  border: 1px solid #FBBA00;
  display: inline-block;
  width: 180px;
  height: 180px;
  margin-right: 40px;
}
.button-group li a {
  width: 100%;
  height: 100%;
  background: #FBBA00;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex-direction: column;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  padding: 15px;
  line-height: 1.2;
}
.button-group li a::before {
  content: "\e918";
  font-family: "icomoon";
  color: #fff;
  font-size: 2.5rem;
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
}
.button-group li a.inloggen::before {
  content: "\ea04";
  font-family: "Nucleo";
}
.button-group li a.afbeeldingen::before {
  content: "\ea02";
  font-family: "Nucleo";
}
.button-group li a.contact::before {
  content: "\e907";
}
.button-group li a.samenwerking::before {
  content: "\e905";
}
.button-group li a.leren::before {
  content: "\e916";
}
.button-group li a.overige::before {
  content: "\e91b";
  font-size: 2rem;
}
.button-group li:nth-child(2) {
  border-color: #95C11F;
}
.button-group li:nth-child(2) a {
  background: #95C11F;
}
.button-group li:nth-child(3) {
  border-color: #E84E0F;
}
.button-group li:nth-child(3) a {
  background: #E84E0F;
}
.button-group li:nth-child(4) {
  border-color: #006EB7;
}
.button-group li:nth-child(4) a {
  background: #006EB7;
}
@media (max-width: 991px) {
  .button-group {
    max-width: 600px;
    text-align: center;
    margin-left: 40px !important;
  }
  .button-group li {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .button-group {
    max-width: 100%;
    margin-left: 20px !important;
    flex-wrap: wrap;
  }
  .button-group li {
    width: 120px;
    height: 120px;
    margin-right: 20px;
    overflow: hidden;
  }
  .button-group li a {
    font-size: 0.75rem;
  }
  .button-group li a::before {
    font-size: 1.5rem;
  }
  .button-group li a.overige::before {
    font-size: 1rem;
  }
}

/*
 * (Responsive) Full video container
 */
.full-video {
  height: 100vh;
}
.full-video .video-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  min-width: 100vw;
  height: 100vh;
}
.full-video .video-container video {
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
}
@media (max-width: 575px) {
  .full-video .video-container video {
    max-height: 100%;
  }
}
.full-video .video-container::after {
  content: "";
  position: absolute;
  background: rgba(37, 41, 50, 0.6);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#header {
  height: 100vh;
}
#header .wrapper {
  height: 100%;
  z-index: 2;
}
#header .wrapper .content {
  height: 100%;
  flex-direction: column;
}
#header .wrapper .content h1, #header .wrapper .content h2 {
  color: #fff;
  text-shadow: 2px 2px rgba(37, 41, 50, 0.5);
}
@media (max-width: 767px) {
  #header .wrapper .content h1 {
    font-size: 2rem;
  }
  #header .wrapper .content h2 {
    font-size: 1.5rem;
  }
  #header .wrapper .content a {
    margin-bottom: 10px;
  }
}
#header .wrapper .content h2 {
  margin-top: 10px;
}
#header.header_1::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background: url(../images/blue_1.png) no-repeat;
  background-size: 100% 100%;
  background-position: right top;
}
.theme-color-2 #header.header_1::after {
  background-image: url(../images/yellow_1.png);
}
.theme-color-3 #header.header_1::after {
  background-image: url(../images/orange_1.png);
}
.theme-color-4 #header.header_1::after {
  background-image: url(../images/green_1.png);
}
#header.header_1 .newsblock {
  position: absolute;
  right: 40px;
  bottom: 100px;
  width: 360px;
}
#header.header_1 .newsblock h3 {
  color: #fff;
}
#header.header_1 .newsblock ul li {
  display: inline-block;
  list-style: none;
  background: #fff;
  box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  overflow: hidden;
}
#header.header_1 .newsblock ul li > div {
  height: 200px;
  background-size: cover;
}
#header.header_1 .newsblock ul li .content {
  height: auto;
  padding: 20px;
}
#header.header_1 .newsblock ul li .content h4 {
  margin: 0 0 10px 0;
}
#header.header_1 .newsblock ul li .content p {
  color: #5D5E5E;
}
@media (max-width: 767px) {
  #header.header_1 .newsblock {
    display: none;
  }
}
#header.header_2 {
  z-index: 101;
  padding: 0;
  min-height: 100vh;
  height: auto;
}
#header.header_2 .top-half {
  width: 100%;
  height: 50vh;
}
#header.header_2 .top-half .button {
  position: absolute;
  bottom: 40px;
  background: #95C11F;
}
#header.header_2 .top-half .scroll {
  left: 40px;
  bottom: -15px;
  border-radius: 100%;
  padding: 8px 5px;
  height: 30px;
  width: 30px;
}
.theme-color-1 #header.header_2 .top-half .scroll {
  background: #FBBA00;
}
.theme-color-2 #header.header_2 .top-half .scroll {
  background: #006EB7;
}
.theme-color-3 #header.header_2 .top-half .scroll {
  background: #95C11F;
}
.theme-color-4 #header.header_2 .top-half .scroll {
  background: #006EB7;
}
#header.header_2 .top-half .scroll span::before {
  font-size: 0.5rem;
}
@media (max-width: 767px) {
  #header.header_2 .top-half .scroll {
    left: 20px;
  }
}
#header.header_2 .bottom-half {
  width: 100%;
  height: 50vh;
  position: relative;
  padding: 40px 0;
}
#header.header_2 .bottom-half::after {
  content: "";
  background: url(../images/stev_mark.png) no-repeat;
  position: absolute;
  right: -200px;
  top: -200px;
  width: 529px;
  height: 513px;
  opacity: 0.5;
}
@media (max-width: 767px) {
  #header.header_2 .bottom-half::after {
    display: none;
  }
}
@media (max-width: 767px) {
  #header.header_2 .bottom-half {
    height: auto;
  }
  #header.header_2 .bottom-half::after {
    display: none;
  }
}
#header.header_2 .bottom-half .wrapper {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
}
#header.header_2 .bottom-half .wrapper > * {
  flex: none;
  margin-right: calc(80px);
  margin-bottom: calc(80px);
  width: calc((100% / 1) - (80px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  #header.header_2 .bottom-half .wrapper > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  #header.header_2 .bottom-half .wrapper > * {
    width: calc((100% / 2) - (80px * (2 - 1) / 2));
  }
  #header.header_2 .bottom-half .wrapper > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #header.header_2 .bottom-half .wrapper > * {
    width: calc((100% / 2) - (80px * (2 - 1) / 2));
  }
  #header.header_2 .bottom-half .wrapper > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  #header.header_2 .bottom-half .wrapper > * {
    width: calc((100% / 2) - (80px * (2 - 1) / 2));
  }
  #header.header_2 .bottom-half .wrapper > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  #header.header_2 .bottom-half .wrapper > * {
    width: calc((100% / 2) - (80px * (2 - 1) / 2));
  }
  #header.header_2 .bottom-half .wrapper > *:nth-child(2n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  #header.header_2 .bottom-half .wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 80px;
  }
  @media (min-width: 576px) {
    #header.header_2 .bottom-half .wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 768px) {
    #header.header_2 .bottom-half .wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    #header.header_2 .bottom-half .wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 1280px) {
    #header.header_2 .bottom-half .wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  #header.header_2 .bottom-half .wrapper > * {
    width: 100%;
    margin: 0;
  }
}
#header.header_2 .bottom-half .wrapper .content h1 {
  margin-top: 0;
  line-height: 1;
  margin-bottom: 10px;
}
#header.header_2 .bottom-half .wrapper .content h1, #header.header_2 .bottom-half .wrapper .content h2 {
  color: #111111;
  text-shadow: none;
  margin-top: 0;
}
#header.header_2 .bottom-half .wrapper .content p {
  color: #000000;
}
#header.header_2 .bottom-half .wrapper .youtube {
  display: flex;
  align-items: flex-start;
}
#header.header_2 .bottom-half .wrapper .youtube .placeholder {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
#header.header_2 .bottom-half .wrapper .youtube .placeholder::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.65;
}
.theme-color-1 #header.header_2 .bottom-half .wrapper .youtube .placeholder::before {
  background: #006EB7;
}
.theme-color-2 #header.header_2 .bottom-half .wrapper .youtube .placeholder::before {
  background: #FBBA00;
}
.theme-color-3 #header.header_2 .bottom-half .wrapper .youtube .placeholder::before {
  background: #E84E0F;
}
.theme-color-4 #header.header_2 .bottom-half .wrapper .youtube .placeholder::before {
  background: #95C11F;
}
#header.header_2 .bottom-half .wrapper .youtube .placeholder::after {
  width: 60px;
  height: 35px;
  content: "\e915";
  font-family: "icomoon";
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
}
.theme-color-1 #header.header_2 .bottom-half .wrapper .youtube .placeholder::after {
  color: #006EB7;
}
.theme-color-2 #header.header_2 .bottom-half .wrapper .youtube .placeholder::after {
  color: #FBBA00;
}
.theme-color-3 #header.header_2 .bottom-half .wrapper .youtube .placeholder::after {
  color: #E84E0F;
}
.theme-color-4 #header.header_2 .bottom-half .wrapper .youtube .placeholder::after {
  color: #95C11F;
}
#header.header_3::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 80%;
  width: 60%;
  pointer-events: none;
  background: url(../images/blue_2.png) no-repeat;
  background-size: 100% 100%;
  background-position: right top;
}
.theme-color-2 #header.header_3::after {
  background-image: url(../images/yellow_2.png);
}
.theme-color-3 #header.header_3::after {
  background-image: url(../images/orange_2.png);
}
.theme-color-4 #header.header_3::after {
  background-image: url(../images/green_2.png);
}
@media (max-width: 767px) {
  #header.header_3 .content > div {
    padding-top: 40px;
  }
}
#header.header_3 .wrapper .extra {
  position: absolute;
  bottom: 0;
}
#header .scroll {
  color: #fff;
  position: absolute;
  z-index: 5;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-weight: 700;
  font-size: 0.875rem;
}
#header .scroll span::before {
  margin-top: 5px;
  color: #fff;
  display: block;
  font-size: 1.75rem;
}
@media (max-width: 767px) {
  #header .scroll {
    bottom: 10px;
  }
}

.news.news-overview .wrapper > ul {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
}
.news.news-overview .wrapper > ul > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .news.news-overview .wrapper > ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .news.news-overview .wrapper > ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .news.news-overview .wrapper > ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .news.news-overview .wrapper > ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .news.news-overview .wrapper > ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .news.news-overview .wrapper > ul > * {
    width: calc((100% / 3) - (20px * (3 - 1) / 3));
  }
  .news.news-overview .wrapper > ul > *:nth-child(3n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .news.news-overview .wrapper > ul > * {
    width: calc((100% / 3) - (20px * (3 - 1) / 3));
  }
  .news.news-overview .wrapper > ul > *:nth-child(3n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .news.news-overview .wrapper > ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    .news.news-overview .wrapper > ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 768px) {
    .news.news-overview .wrapper > ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .news.news-overview .wrapper > ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .news.news-overview .wrapper > ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .news.news-overview .wrapper > ul > * {
    width: 100%;
    margin: 0;
  }
}
.news.news-overview .wrapper > ul li {
  margin: 0 !important;
}
.news.news-overview.news__2 .wrapper > ul {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
}
.news.news-overview.news__2 .wrapper > ul > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .news.news-overview.news__2 .wrapper > ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .news.news-overview.news__2 .wrapper > ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .news.news-overview.news__2 .wrapper > ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .news.news-overview.news__2 .wrapper > ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .news.news-overview.news__2 .wrapper > ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .news.news-overview.news__2 .wrapper > ul > * {
    width: calc((100% / 3) - (20px * (3 - 1) / 3));
  }
  .news.news-overview.news__2 .wrapper > ul > *:nth-child(3n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .news.news-overview.news__2 .wrapper > ul > * {
    width: calc((100% / 3) - (20px * (3 - 1) / 3));
  }
  .news.news-overview.news__2 .wrapper > ul > *:nth-child(3n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .news.news-overview.news__2 .wrapper > ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    .news.news-overview.news__2 .wrapper > ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 768px) {
    .news.news-overview.news__2 .wrapper > ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .news.news-overview.news__2 .wrapper > ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .news.news-overview.news__2 .wrapper > ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .news.news-overview.news__2 .wrapper > ul > * {
    width: 100%;
    margin: 0;
  }
}
.news .slick-track {
  display: flex !important;
}
.news li {
  position: relative;
}
.news li .school-logo {
  position: absolute;
  left: 10px;
  top: 10px;
}
.news li .school-logo img {
  width: 25px;
  height: 25px;
}
.news.news__1 {
  position: relative;
  height: auto;
}
.news.news__1 .wrapper {
  position: relative;
  z-index: 3;
  text-align: center;
}
.news.news__1 .wrapper h1 {
  margin-bottom: -80px;
  text-align: left;
  margin-left: 20px;
}
@media (max-width: 575px) {
  .news.news__1 .wrapper h1 {
    margin-bottom: 20px;
  }
}
.news.news__1 .wrapper > ul {
  position: relative;
  overflow: hidden;
  padding: 80px 0px 40px 0px;
  text-align: left;
  margin: 0;
}
.news.news__1 .wrapper > ul .slider-btn {
  background: #1D2C35;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  right: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #1D2C35;
  transition: all 350ms ease;
}
.news.news__1 .wrapper > ul .slider-btn.prev-slider {
  right: 100px;
}
.news.news__1 .wrapper > ul .slider-btn span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  font-weight: bold;
}
.news.news__1 .wrapper > ul .slider-btn span:before {
  color: #fff;
  transition: all 350ms ease;
}
.news.news__1 .wrapper > ul .slider-btn:hover {
  background: none;
}
.news.news__1 .wrapper > ul .slider-btn:hover span:before {
  color: #1D2C35;
}
@media (max-width: 575px) {
  .news.news__1 .wrapper > ul .slider-btn {
    left: 100px;
  }
  .news.news__1 .wrapper > ul .slider-btn.prev-slider {
    left: 20px;
  }
}
.news.news__1 .wrapper > ul li {
  display: inline-block;
  list-style: none;
  background: #fff;
  box-shadow: 0 2px 38px 0 rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  margin: 20px;
  overflow: hidden;
}
.news.news__1 .wrapper > ul li > div {
  height: 200px;
  background-size: cover;
}
.news.news__1 .wrapper > ul li .content {
  height: auto;
  padding: 20px;
}
.news.news__1 .wrapper > ul li .content h4 {
  margin: 0 0 10px 0;
}
.news.news__1 .wrapper > ul li .content p {
  color: #5D5E5E;
}
.news.news__1::after {
  content: " ";
  display: block;
  width: 100%;
  position: absolute;
  height: 50%;
  bottom: 0;
  z-index: 1;
  background-image: url("../images/tools_background.png");
  background-size: auto 100%;
}
.theme-color-1 .news.news__1::after {
  background-color: #006EB7;
}
.theme-color-2 .news.news__1::after {
  background-color: #FBBA00;
}
.theme-color-3 .news.news__1::after {
  background-color: #E84E0F;
}
.theme-color-4 .news.news__1::after {
  background-color: #95C11F;
}
.news.news__1::before {
  content: " ";
  display: block;
  width: 120%;
  position: absolute;
  height: 200px;
  transform: rotate(-2deg);
  top: 50%;
  margin-top: -100px;
  z-index: 1;
  left: -10px;
}
.theme-color-1 .news.news__1::before {
  background-color: #006EB7;
}
.theme-color-2 .news.news__1::before {
  background-color: #FBBA00;
}
.theme-color-3 .news.news__1::before {
  background-color: #E84E0F;
}
.theme-color-4 .news.news__1::before {
  background-color: #95C11F;
}
.news.news__2 {
  background-image: url("../images/tools_background_lighter.png");
  background-size: auto 100%;
  background-position: center bottom;
}
.news.news__2 .wrapper {
  text-align: center;
}
.news.news__2 .wrapper h1 {
  margin-bottom: 60px;
}
.news.news__2 .wrapper .button {
  margin-top: 60px;
}
.news.news__2 .wrapper > ul {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
  padding: 0;
  text-align: left;
}
.news.news__2 .wrapper > ul > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .news.news__2 .wrapper > ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .news.news__2 .wrapper > ul > * {
    width: calc((100% / 1) - (20px * (1 - 1) / 1));
  }
  .news.news__2 .wrapper > ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .news.news__2 .wrapper > ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .news.news__2 .wrapper > ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .news.news__2 .wrapper > ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .news.news__2 .wrapper > ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .news.news__2 .wrapper > ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .news.news__2 .wrapper > ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .news.news__2 .wrapper > ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    .news.news__2 .wrapper > ul {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 768px) {
    .news.news__2 .wrapper > ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .news.news__2 .wrapper > ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .news.news__2 .wrapper > ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .news.news__2 .wrapper > ul > * {
    width: 100%;
    margin: 0;
  }
}
.news.news__2 .wrapper > ul li {
  background-size: cover;
  list-style: none;
  box-shadow: 0 2px 38px 0 rgba(0, 0, 0, 0.23);
  position: relative;
  height: 370px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}
.news.news__2 .wrapper > ul li .school-logo {
  left: auto;
  right: 10px;
}
.news.news__2 .wrapper > ul li .image {
  height: 100%;
  background-size: cover;
}
.news.news__2 .wrapper > ul li .content {
  position: absolute;
  width: 80%;
  color: #fff;
  bottom: 20px;
  left: 20px;
}
.news.news__2 .wrapper > ul li .content h4 {
  color: #fff;
  margin: 0 0 10px 0;
}
.news.news__2 .wrapper > ul li .content p {
  line-height: 1.5rem;
}
.news.news__2 .wrapper > ul li .content a {
  margin-top: 0;
}
.news.news__2 .wrapper > ul li:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
}
.news.news__3 .wrapper .text {
  width: calc(33% - 20px);
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
}
.news.news__3 .wrapper .text a:not(.button) {
  text-decoration: none;
}
.theme-color-1 .news.news__3 .wrapper .text a:not(.button) {
  color: #006EB7;
}
.theme-color-2 .news.news__3 .wrapper .text a:not(.button) {
  color: #FBBA00;
}
.theme-color-3 .news.news__3 .wrapper .text a:not(.button) {
  color: #E84E0F;
}
.theme-color-4 .news.news__3 .wrapper .text a:not(.button) {
  color: #95C11F;
}
.news.news__3 .wrapper .text p {
  margin: 30px 0;
  display: block;
  color: #5D5E5E;
}
.news.news__3 .wrapper .text span {
  font-size: 20px;
  margin-right: 20px;
}
.news.news__3 .wrapper .text .socials {
  margin-bottom: 20px;
}
.news.news__3 .wrapper .text .socials a {
  margin-right: 20px;
  font-size: 20px;
}
.news.news__3 .wrapper .news-items {
  width: calc(66% - 20px);
  display: inline-block;
  vertical-align: top;
}
.news.news__3 .wrapper .news-items > ul {
  position: relative;
  padding: 40px 0px 40px 0px;
  text-align: left;
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
  margin: 0;
}
.news.news__3 .wrapper .news-items > ul > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .news.news__3 .wrapper .news-items > ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .news.news__3 .wrapper .news-items > ul > * {
    width: calc((100% / 1) - (20px * (1 - 1) / 1));
  }
  .news.news__3 .wrapper .news-items > ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .news.news__3 .wrapper .news-items > ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .news.news__3 .wrapper .news-items > ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .news.news__3 .wrapper .news-items > ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .news.news__3 .wrapper .news-items > ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .news.news__3 .wrapper .news-items > ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .news.news__3 .wrapper .news-items > ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .news.news__3 .wrapper .news-items > ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    .news.news__3 .wrapper .news-items > ul {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 768px) {
    .news.news__3 .wrapper .news-items > ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .news.news__3 .wrapper .news-items > ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .news.news__3 .wrapper .news-items > ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .news.news__3 .wrapper .news-items > ul > * {
    width: 100%;
    margin: 0;
  }
}
.news.news__3 .wrapper .news-items > ul li {
  display: inline-block;
  list-style: none;
  background: #fff;
  box-shadow: 0 2px 38px 0 rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
}
.news.news__3 .wrapper .news-items > ul li > div {
  height: 200px;
  background-size: cover;
}
.news.news__3 .wrapper .news-items > ul li .content {
  height: auto;
  padding: 20px;
}
.news.news__3 .wrapper .news-items > ul li .content h4 {
  margin: 0 0 10px 0;
}
.news.news__3 .wrapper .news-items > ul li .content p {
  color: #5D5E5E;
}
@media (max-width: 767px) {
  .news.news__3 .wrapper .news-items > ul li:nth-child(3) {
    display: none;
  }
  .news.news__3 .wrapper .news-items > ul li:nth-child(4) {
    display: none;
  }
}
@media (max-width: 991px) {
  .news.news__3 .wrapper .text,
.news.news__3 .wrapper .news-items {
    display: block;
    width: 100%;
  }
}

.event {
  padding: 80px 0;
}
.event .button {
  margin-top: 50px;
}
.event.event__1 h1 {
  margin-bottom: 50px;
}
.event.event__1 ul {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
  padding: 0;
  margin: 0;
}
.event.event__1 ul > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .event.event__1 ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .event.event__1 ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .event.event__1 ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .event.event__1 ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .event.event__1 ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .event.event__1 ul > * {
    width: calc((100% / 3) - (20px * (3 - 1) / 3));
  }
  .event.event__1 ul > *:nth-child(3n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .event.event__1 ul > * {
    width: calc((100% / 3) - (20px * (3 - 1) / 3));
  }
  .event.event__1 ul > *:nth-child(3n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .event.event__1 ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    .event.event__1 ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 768px) {
    .event.event__1 ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .event.event__1 ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .event.event__1 ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .event.event__1 ul > * {
    width: 100%;
    margin: 0;
  }
}
.event.event__1 ul li {
  display: inline-block;
  list-style: none;
}
.event.event__1 ul li .event-title {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  height: 70px;
}
.theme-color-1 .event.event__1 ul li .event-title {
  background: rgba(0, 110, 183, 0.7);
}
.theme-color-2 .event.event__1 ul li .event-title {
  background: rgba(251, 186, 0, 0.7);
}
.theme-color-3 .event.event__1 ul li .event-title {
  background: rgba(232, 78, 15, 0.7);
}
.theme-color-4 .event.event__1 ul li .event-title {
  background: rgba(149, 193, 31, 0.7);
}
.event.event__1 ul li .event-title .date {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: 70px;
  height: 70px;
  color: #fff;
  box-sizing: border-box;
  padding-top: 5px;
}
.theme-color-1 .event.event__1 ul li .event-title .date {
  background: #006EB7;
}
.theme-color-2 .event.event__1 ul li .event-title .date {
  background: #FBBA00;
}
.theme-color-3 .event.event__1 ul li .event-title .date {
  background: #E84E0F;
}
.theme-color-4 .event.event__1 ul li .event-title .date {
  background: #95C11F;
}
.event.event__1 ul li .event-title .date b {
  display: block;
  margin-bottom: -10px;
}
.event.event__1 ul li .event-title h4 {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.event.event__1 ul li .read-more {
  margin-top: -10px;
  display: block;
  text-decoration: none;
}
.theme-color-1 .event.event__1 ul li .read-more {
  color: #006EB7;
}
.theme-color-2 .event.event__1 ul li .read-more {
  color: #FBBA00;
}
.theme-color-3 .event.event__1 ul li .read-more {
  color: #E84E0F;
}
.theme-color-4 .event.event__1 ul li .read-more {
  color: #95C11F;
}
.event.event__1 ul li .read-more span {
  font-size: 15px;
  font-weight: bold;
}
.event.event__1.dark {
  background: #1D2C35;
}
.event.event__1.dark h1 {
  color: #fff;
}
.event.event__1.dark p {
  color: #fff;
}
.event.event__2 {
  background-image: url("../images/tools_background_lighter.png");
  background-size: auto 100%;
  background-position: center bottom;
}
.event.event__2 h1 {
  margin-bottom: 50px;
}
.event.event__2 ul {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
  padding: 0;
  margin: 0;
}
.event.event__2 ul > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .event.event__2 ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .event.event__2 ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .event.event__2 ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .event.event__2 ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .event.event__2 ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .event.event__2 ul > * {
    width: calc((100% / 3) - (20px * (3 - 1) / 3));
  }
  .event.event__2 ul > *:nth-child(3n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .event.event__2 ul > * {
    width: calc((100% / 3) - (20px * (3 - 1) / 3));
  }
  .event.event__2 ul > *:nth-child(3n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .event.event__2 ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    .event.event__2 ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 768px) {
    .event.event__2 ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .event.event__2 ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .event.event__2 ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .event.event__2 ul > * {
    width: 100%;
    margin: 0;
  }
}
.event.event__2 ul li {
  display: inline-block;
  position: relative;
  list-style: none;
  border-radius: 5px;
  color: #fff;
  padding: 15px;
  padding-left: 90px;
}
.theme-color-1 .event.event__2 ul li {
  background: #006EB7;
}
.theme-color-2 .event.event__2 ul li {
  background: #FBBA00;
}
.theme-color-3 .event.event__2 ul li {
  background: #E84E0F;
}
.theme-color-4 .event.event__2 ul li {
  background: #95C11F;
}
.event.event__2 ul li .date {
  position: absolute;
  top: 15px;
  left: 0;
  background: #1D2C35;
  text-align: center;
  width: 70px;
  height: 70px;
  color: #fff;
  box-sizing: border-box;
  padding-top: 5px;
  border-radius: 0px 10px 10px 0px;
}
.event.event__2 ul li .date b {
  display: block;
  margin-bottom: -10px;
}
.event.event__2 ul li h4 {
  color: #fff;
  margin: 0 0 10px 0;
}
.event.event__2 ul li p {
  line-height: 1.6rem;
}
.event.event__2 ul li .read-more {
  margin-top: -10px;
  display: block;
  text-decoration: none;
  color: #fff;
}
.event.event__2 ul li .read-more span {
  font-size: 15px;
  font-weight: bold;
}
.event.event__2 ul li .read-more span:before {
  color: #fff;
}
.event.event__2.dark {
  background-color: #1D2C35;
  background-image: url("../images/tools_background_lighter.png");
  background-size: auto 100%;
  background-position: center bottom;
}
.event.event__2.dark h1 {
  color: #fff;
}
.event.event__2.dark p {
  color: #fff;
}

.group {
  padding: 80px 0;
  overflow: hidden;
}
.group.group__1 h1 {
  margin-bottom: 50px;
}
.group.group__1 ul {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
  margin: 0;
  padding: 0;
}
.group.group__1 ul > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .group.group__1 ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .group.group__1 ul > * {
    width: calc((100% / 1) - (20px * (1 - 1) / 1));
  }
  .group.group__1 ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .group.group__1 ul > * {
    width: calc((100% / 1) - (20px * (1 - 1) / 1));
  }
  .group.group__1 ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .group.group__1 ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .group.group__1 ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .group.group__1 ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .group.group__1 ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .group.group__1 ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    .group.group__1 ul {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 768px) {
    .group.group__1 ul {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 992px) {
    .group.group__1 ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .group.group__1 ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .group.group__1 ul > * {
    width: 100%;
    margin: 0;
  }
}
.group.group__1 ul li {
  list-style: none;
  display: block;
  padding: 20px;
  padding-left: 140px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  min-width: 0;
}
.group.group__1 ul li h2 {
  font-size: 1.6rem;
  margin: 0;
}
.group.group__1 ul li .image {
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 120px;
  background-size: cover;
  background-position: center;
}
.group.group__1 ul li .border {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 8px;
  background: #006EB7;
}
.group.group__1 ul li .variations {
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  transition: all 350ms ease;
  position: absolute;
  right: 15px;
  top: 50%;
  transition-delay: 300ms;
}
.group.group__1 ul li .variations a {
  border: 2px solid #006EB7;
  padding: 10px 15px;
  margin-right: 10px;
  box-sizing: border-box;
  border-radius: 100px;
  text-decoration: none;
  color: #006EB7;
  transition: all 350ms ease;
}
.group.group__1 ul li .variations a:hover {
  background: #006EB7;
  color: #fff;
}
.group.group__1 ul li:nth-child(2n+2) .border {
  background: #E84E0F;
}
.group.group__1 ul li:nth-child(2n+2) h2 {
  color: #E84E0F;
}
.group.group__1 ul li:nth-child(2n+2) .variations a {
  color: #E84E0F;
  border-color: #E84E0F;
}
.group.group__1 ul li:nth-child(2n+2) .variations a:hover {
  background: #E84E0F;
  color: #fff;
}
.group.group__1 ul li:nth-child(3n+1) .border {
  background: #FBBA00;
}
.group.group__1 ul li:nth-child(3n+1) h2 {
  color: #FBBA00;
}
.group.group__1 ul li:nth-child(3n+1) .variations a {
  color: #FBBA00;
  border-color: #FBBA00;
}
.group.group__1 ul li:nth-child(3n+1) .variations a:hover {
  background: #FBBA00;
  color: #fff;
}
.group.group__1 ul li:nth-child(4n+2) .border {
  background: #95C11F;
}
.group.group__1 ul li:nth-child(4n+2) h2 {
  color: #95C11F;
}
.group.group__1 ul li:nth-child(4n+2) .variations a {
  color: #95C11F;
  border-color: #95C11F;
}
.group.group__1 ul li:nth-child(4n+2) .variations a:hover {
  background: #95C11F;
  color: #fff;
}
@media (min-width: 768px) {
  .group.group__1 ul li:hover .variations {
    transition-delay: 0ms;
    transform: translateY(-50%);
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 767px) {
  .group.group__1 ul li {
    height: 100%;
  }
  .group.group__1 ul li span {
    white-space: normal;
  }
  .group.group__1 ul li .variations {
    transform: translateY(0%);
    opacity: 1;
    display: block;
    box-sizing: border-box;
    margin-bottom: 40px;
    visibility: visible;
    position: relative;
    margin-left: 15px;
    top: 30px;
  }
}
.group.group__2 {
  position: relative;
  padding: 100px 0 140px 0;
  background-image: url("../images/tools_background_lighter.png");
  background-size: auto 100%;
  background-position: center bottom;
}
.group.group__2:after {
  content: " ";
  width: 100%;
  height: 2px;
  background: #006EB7;
  position: absolute;
  background: linear-gradient(90deg, #006EB7 0%, #95C11F 31.11%, #FBBA00 58.25%, #E84E0F 100%);
  top: 255px;
  z-index: 1;
  display: block;
  left: 0;
  right: 0;
}
.group.group__2 .slider-btn {
  background: #1D2C35;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  position: absolute;
  top: -80px;
  right: 20px;
  z-index: 3;
  text-align: center;
  cursor: pointer;
  border: 2px solid #1D2C35;
  transition: all 350ms ease;
}
.group.group__2 .slider-btn.prev-slider {
  right: 100px;
}
.group.group__2 .slider-btn span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  font-weight: bold;
}
.group.group__2 .slider-btn span:before {
  color: #fff;
  transition: all 350ms ease;
}
.group.group__2 .slider-btn:hover {
  background: none;
}
.group.group__2 .slider-btn:hover span:before {
  color: #1D2C35;
}
@media (max-width: 575px) {
  .group.group__2 .slider-btn {
    top: -80px;
    left: 80px;
    margin-bottom: 100px;
  }
  .group.group__2 .slider-btn.prev-slider {
    left: 0px;
  }
}
.group.group__2 .slider-btn.slick-disabled {
  opacity: 0.1;
  pointer-events: none;
  cursor: default;
}
@media (max-width: 575px) {
  .group.group__2 {
    padding-top: 150px;
  }
  .group.group__2 .slick-list {
    margin-top: 100px !important;
  }
  .group.group__2 h1 {
    margin-top: -120px;
  }
}
.group.group__2 ul {
  z-index: 3;
  position: relative;
  margin-top: 20px;
}
.group.group__2 ul .slick-slide {
  margin-left: 20px;
}
.group.group__2 ul .slick-list {
  margin-left: -60px;
}
.group.group__2 ul li {
  list-style: none;
  display: inline-block;
  text-align: center;
}
.group.group__2 ul li .group-block {
  width: 70px;
  height: 70px;
  border-radius: 6px;
  background: #006EB7;
  display: block;
  position: relative;
  margin: 20px auto;
}
.group.group__2 ul li .group-block span {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.group.group__2 ul li .group-block:after {
  content: " ";
  width: 1px;
  height: 22px;
  background: #006EB7;
  bottom: -20px;
  display: block;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.group.group__2 ul li .image {
  width: 100%;
  border-radius: 11px;
  display: block;
  background-color: #D8D8D8;
  text-align: center;
  height: 200px;
  position: relative;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.group.group__2 ul li .image .variations {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  transition: all 350ms ease;
}
.group.group__2 ul li .image .variations a {
  text-decoration: none;
  border: 2px solid #006EB7;
  border-radius: 100px;
  padding: 10px 15px;
  margin-right: 10px;
  color: #006EB7;
  transition: all 350ms ease;
}
.group.group__2 ul li .image .variations a:hover {
  background: #006EB7;
  color: #fff;
}
@media (min-width: 768px) {
  .group.group__2 ul li .image:hover .variations {
    transform: translateY(-50%);
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 767px) {
  .group.group__2 ul li .image .variations {
    transform: translateY(-50%);
    opacity: 1;
    visibility: visible;
  }
}
.group.group__2 ul li:nth-child(2n+2) .group-block {
  background: #E84E0F;
}
.group.group__2 ul li:nth-child(2n+2) .group-block::after {
  background: #E84E0F;
}
.group.group__2 ul li:nth-child(2n+2) .variations a {
  color: #E84E0F;
  border-color: #E84E0F;
}
.group.group__2 ul li:nth-child(2n+2) .variations a:hover {
  background: #E84E0F;
  color: #fff;
}
.group.group__2 ul li:nth-child(3n+3) .group-block {
  background: #FBBA00;
}
.group.group__2 ul li:nth-child(3n+3) .group-block::after {
  background: #FBBA00;
}
.group.group__2 ul li:nth-child(3n+3) .variations a {
  color: #FBBA00;
  border-color: #FBBA00;
}
.group.group__2 ul li:nth-child(3n+3) .variations a:hover {
  background: #FBBA00;
  color: #fff;
}
.group.group__2 ul li:nth-child(4n+4) .group-block {
  background: #95C11F;
}
.group.group__2 ul li:nth-child(4n+4) .group-block::after {
  background: #95C11F;
}
.group.group__2 ul li:nth-child(4n+4) .variations a {
  color: #95C11F;
  border-color: #95C11F;
}
.group.group__2 ul li:nth-child(4n+4) .variations a:hover {
  background: #95C11F;
  color: #fff;
}
.group.group__2.dark {
  background: #1D2C35;
}
.group.group__2.dark h1 {
  color: #fff;
}
.group.group__2.dark .slider-btn {
  background: #fff;
  border-color: #fff;
}
.group.group__2.dark .slider-btn span:before {
  color: #1D2C35;
}
.group.group__3 {
  background: #1D2C35;
  padding-bottom: 120px;
}
.group.group__3 h1 {
  margin-bottom: 40px;
  color: #fff;
}
.group.group__3 ul {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
  padding: 0;
  margin: 0;
}
.group.group__3 ul > * {
  flex: none;
  margin-right: calc(0px);
  margin-bottom: calc(0px);
  width: calc((100% / 1) - (0px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .group.group__3 ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .group.group__3 ul > * {
    width: calc((100% / 2) - (0px * (2 - 1) / 2));
  }
  .group.group__3 ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .group.group__3 ul > * {
    width: calc((100% / 3) - (0px * (3 - 1) / 3));
  }
  .group.group__3 ul > *:nth-child(3n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .group.group__3 ul > * {
    width: calc((100% / 4) - (0px * (4 - 1) / 4));
  }
  .group.group__3 ul > *:nth-child(4n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .group.group__3 ul > * {
    width: calc((100% / 4) - (0px * (4 - 1) / 4));
  }
  .group.group__3 ul > *:nth-child(4n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .group.group__3 ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
  @media (min-width: 576px) {
    .group.group__3 ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 768px) {
    .group.group__3 ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 992px) {
    .group.group__3 ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .group.group__3 ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .group.group__3 ul > * {
    width: 100%;
    margin: 0;
  }
}
.group.group__3 ul li {
  list-style: none;
  height: 200px;
  background-size: cover;
  position: relative;
  background-size: cover;
  background-position: center;
  text-align: center;
  cursor: pointer;
}
.group.group__3 ul li:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(29, 44, 53, 0.12) 0.06%, #1D2C35 95.87%);
  z-index: 1;
}
.group.group__3 ul li .content {
  position: absolute;
  z-index: 2;
  color: #fff;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
}
.group.group__3 ul li .content b {
  color: #006EB7;
  margin-bottom: -10px;
  display: block;
}
.group.group__3 ul li .content span {
  display: block;
  margin-left: 15px;
}
.group.group__3 ul li .variations {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  transition: all 350ms ease;
  z-index: 3;
}
.group.group__3 ul li .variations a {
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 100px;
  padding: 10px 15px;
  margin-right: 10px;
  color: #fff;
  transition: all 350ms ease;
}
.group.group__3 ul li .variations a:hover {
  background: #fff;
  color: #006EB7;
}
@media (min-width: 768px) {
  .group.group__3 ul li:hover .variations {
    transform: translateY(-60%);
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 767px) {
  .group.group__3 ul li .variations {
    transform: translateY(-50%);
    opacity: 1;
    visibility: visible;
  }
}
.group.group__3 ul li:nth-child(2n+2) .content b {
  color: #95C11F;
}
.group.group__3 ul li:nth-child(3n+3) .content b {
  color: #E84E0F;
}
.group.group__3 ul li:nth-child(4n+4) .content b {
  color: #FBBA00;
}

.group-detail {
  position: relative;
}
.group-detail .group-picture {
  width: 100%;
  max-width: 100%;
  margin: 25px auto;
  position: relative;
}

.teachers {
  margin-bottom: 40px;
  display: block;
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
}
.teachers > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .teachers > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .teachers > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .teachers > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .teachers > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .teachers > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .teachers > * {
    width: calc((100% / 3) - (20px * (3 - 1) / 3));
  }
  .teachers > *:nth-child(3n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .teachers > * {
    width: calc((100% / 3) - (20px * (3 - 1) / 3));
  }
  .teachers > *:nth-child(3n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .teachers {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    .teachers {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 768px) {
    .teachers {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .teachers {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .teachers {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .teachers > * {
    width: 100%;
    margin: 0;
  }
}
.teachers .teacher {
  display: inline-block;
  background: #f8f8f8;
  border-radius: 200px;
  min-width: 0;
}
.teachers .teacher .avatar {
  background-size: cover;
  background-position: center;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.teachers .teacher h4 {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
  width: calc(100% - 130px);
  height: calc(100% - 50px);
}
.teachers .teacher span {
  color: #5D5E5E;
  font-size: 0.875rem;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cta-bar {
  background-size: cover;
  background-position: center;
  padding: 200px 15px;
  position: relative;
}
.cta-bar .wrapper .content {
  position: relative;
  z-index: 3;
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
}
.cta-bar .wrapper .content .button {
  min-width: 250px;
}
.cta-bar .wrapper .content p {
  display: block;
  margin: 20px 0 35px 0;
  color: #fff;
}
.cta-bar .wrapper .content h3 {
  font-size: 3rem;
  margin: 0 !important;
  padding: 0 !important;
  color: #fff;
}

.text-block {
  padding: 80px 0;
}
.text-block.text-block-1 img, .text-block.text-block-1 .video, .text-block.text-block-2 img, .text-block.text-block-2 .video {
  display: inline-block;
  width: calc(40% - 20px);
  margin-right: 30px;
  vertical-align: top;
}
.text-block.text-block-1 .text, .text-block.text-block-2 .text {
  display: inline-block;
  width: calc(60% - 20px);
  vertical-align: top;
}
.text-block.text-block-1 .text h1, .text-block.text-block-2 .text h1 {
  margin: 0 0 20px 0;
  padding: 0;
}
.text-block.text-block-1 .text p, .text-block.text-block-2 .text p {
  font-size: 18px;
}
@media (max-width: 991px) {
  .text-block.text-block-1 img, .text-block.text-block-2 img {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    margin-right: 0;
  }
  .text-block.text-block-1 .text, .text-block.text-block-2 .text {
    display: block;
    width: 100%;
  }
}
.text-block.text-block-2 img, .text-block.text-block-2 .video {
  margin-right: 0px;
}
.text-block.text-block-2 .text {
  margin-right: 30px;
}
.text-block.text-block-3 {
  padding-top: 0;
}
.text-block.text-block-3 .image {
  position: relative;
  width: 110%;
  transform: translateX(-5%);
  height: 350px;
  background-size: cover;
  background-position: center;
  margin: 0px 0 40px 0;
  border-bottom-right-radius: 50% 40%;
  border-bottom-left-radius: 50% 40%;
  color: white;
  text-align: center;
  text-indent: 0.1em;
}
@media (max-width: 1279px) {
  .text-block.text-block-3 .image {
    border-bottom-right-radius: 50% 70%;
    border-bottom-left-radius: 50% 70%;
  }
}
@media (max-width: 767px) {
  .text-block.text-block-3 .image {
    border-bottom-right-radius: 60% 50%;
    border-bottom-left-radius: 60% 50%;
  }
}
.text-block.text-block-3 .text {
  text-align: center;
}
.theme-color-1 .text-block.dark {
  background: #006EB7;
}
.theme-color-2 .text-block.dark {
  background: #FBBA00;
}
.theme-color-3 .text-block.dark {
  background: #E84E0F;
}
.theme-color-4 .text-block.dark {
  background: #95C11F;
}
.text-block.dark .text li, .text-block.dark .text p, .text-block.dark .text h1, .text-block.dark .text h2, .text-block.dark .text h3, .text-block.dark .text h4, .text-block.dark .text h5, .text-block.dark .text h6, .text-block.dark .text blockquote, .text-block.dark .text div, .text-block.dark .text td, .text-block.dark .text label {
  color: #fff;
}
@media (max-width: 767px) {
  .text-block {
    overflow: hidden;
    padding: 20px 0;
  }
  .text-block .video {
    width: 100%;
  }
}

.gallery h1 {
  margin-bottom: 20px;
}
.gallery.gallery__1 .wrapper .slick-slider {
  position: relative;
}
.gallery.gallery__1 .wrapper ul {
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.gallery.gallery__1 .wrapper ul li {
  list-style: none;
  display: inline-block;
  vertical-align: top;
}
.gallery.gallery__1 .slider-btn {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  text-align: center;
  cursor: pointer;
  border: 2px solid #1D2C35;
  transition: all 350ms ease;
}
.theme-color-1 .gallery.gallery__1 .slider-btn {
  background: #006EB7;
}
.theme-color-2 .gallery.gallery__1 .slider-btn {
  background: #FBBA00;
}
.theme-color-3 .gallery.gallery__1 .slider-btn {
  background: #E84E0F;
}
.theme-color-4 .gallery.gallery__1 .slider-btn {
  background: #95C11F;
}
.theme-color-1 .gallery.gallery__1 .slider-btn {
  border-color: #006EB7;
}
.theme-color-2 .gallery.gallery__1 .slider-btn {
  border-color: #FBBA00;
}
.theme-color-3 .gallery.gallery__1 .slider-btn {
  border-color: #E84E0F;
}
.theme-color-4 .gallery.gallery__1 .slider-btn {
  border-color: #95C11F;
}
.gallery.gallery__1 .slider-btn.prev-slider {
  left: 10px;
}
.gallery.gallery__1 .slider-btn.next-slider {
  right: 10px;
}
.gallery.gallery__1 .slider-btn span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  font-weight: bold;
}
.gallery.gallery__1 .slider-btn span:before {
  color: #fff;
  transition: all 350ms ease;
}
.gallery.gallery__1 .slider-btn:hover {
  background: none;
}
.theme-color-1 .gallery.gallery__1 .slider-btn:hover span:before {
  color: #006EB7;
}
.theme-color-2 .gallery.gallery__1 .slider-btn:hover span:before {
  color: #FBBA00;
}
.theme-color-3 .gallery.gallery__1 .slider-btn:hover span:before {
  color: #E84E0F;
}
.theme-color-4 .gallery.gallery__1 .slider-btn:hover span:before {
  color: #95C11F;
}
.gallery.gallery__1 .slider-btn.slick-disabled {
  opacity: 0.1;
  pointer-events: none;
  cursor: default;
}
.gallery.gallery__2 .wrapper {
  position: relative;
}
.gallery.gallery__2 .wrapper ul {
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
}
.gallery.gallery__2 .wrapper ul > * {
  flex: none;
  margin-right: calc(20px);
  margin-bottom: calc(20px);
  width: calc((100% / 1) - (20px * (1 - 1) / 1));
}
@media (max-width: 575px) {
  .gallery.gallery__2 .wrapper ul > *:nth-child(1n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .gallery.gallery__2 .wrapper ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .gallery.gallery__2 .wrapper ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gallery.gallery__2 .wrapper ul > * {
    width: calc((100% / 2) - (20px * (2 - 1) / 2));
  }
  .gallery.gallery__2 .wrapper ul > *:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .gallery.gallery__2 .wrapper ul > * {
    width: calc((100% / 4) - (20px * (4 - 1) / 4));
  }
  .gallery.gallery__2 .wrapper ul > *:nth-child(4n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .gallery.gallery__2 .wrapper ul > * {
    width: calc((100% / 4) - (20px * (4 - 1) / 4));
  }
  .gallery.gallery__2 .wrapper ul > *:nth-child(4n) {
    margin-right: 0;
  }
}
@supports (display: grid) {
  .gallery.gallery__2 .wrapper ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: 576px) {
    .gallery.gallery__2 .wrapper ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 768px) {
    .gallery.gallery__2 .wrapper ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .gallery.gallery__2 .wrapper ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .gallery.gallery__2 .wrapper ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .gallery.gallery__2 .wrapper ul > * {
    width: 100%;
    margin: 0;
  }
}
.gallery.gallery__2 .wrapper ul li {
  list-style: none;
  display: inline-block;
}
.gallery.gallery__2 .wrapper ul li img {
  max-width: 100%;
}
.gallery.dark {
  background: #1D2C35;
}
.gallery.dark .wrapper h1 {
  color: #fff;
}
.theme-color-1 .gallery.special {
  background: #006EB7;
}
.theme-color-2 .gallery.special {
  background: #FBBA00;
}
.theme-color-3 .gallery.special {
  background: #E84E0F;
}
.theme-color-4 .gallery.special {
  background: #95C11F;
}
.gallery.special h1 {
  color: #fff;
}

.quicklinks {
  padding: 40px 0 80px 0;
}
.quicklinks .wrapper h3 {
  font-size: 2.5rem;
}
.quicklinks .wrapper ul {
  margin: 0 auto;
}
.quicklinks.dark {
  background: #1D2C35;
}
.quicklinks.panoramas ul {
  flex-wrap: wrap;
}

.downloads {
  padding: 40px 0;
}
.downloads .box {
  border-radius: 20px;
  padding: 20px;
}
.theme-color-1 .downloads .box {
  background: #006EB7;
}
.theme-color-2 .downloads .box {
  background: #FBBA00;
}
.theme-color-3 .downloads .box {
  background: #E84E0F;
}
.theme-color-4 .downloads .box {
  background: #95C11F;
}
.downloads .box h3 {
  color: #fff;
  font-weight: 2.5rem;
  margin-top: 0;
}
.downloads .box p {
  color: #fff;
}
.downloads .box ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 700;
}
.downloads .box ul li a::before {
  content: "\ea03";
  font-family: "Nucleo";
  margin-right: 10px;
  display: inline-block;
  transform: translateY(2px);
}
.theme-color-1 .downloads .box ul li a::before {
  color: #FBBA00;
}
.theme-color-2 .downloads .box ul li a::before {
  color: #006EB7;
}
.theme-color-3 .downloads .box ul li a::before {
  color: #95C11F;
}
.theme-color-4 .downloads .box ul li a::before {
  color: #006EB7;
}
.theme-color-1 .downloads.dark {
  background: #006EB7;
}
.theme-color-2 .downloads.dark {
  background: #FBBA00;
}
.theme-color-3 .downloads.dark {
  background: #E84E0F;
}
.theme-color-4 .downloads.dark {
  background: #95C11F;
}
.downloads.dark .box {
  background: #fff;
}
.downloads.dark .box h3 {
  font-weight: 2.5rem;
  color: #111111;
}
.downloads.dark .box p {
  color: #111111;
}
.downloads.dark .box a {
  color: #111111;
}

/*
 * WP paging styling
 */
.paging .page-numbers {
  list-style: none;
  margin: 0;
  padding: 0;
}
.paging .page-numbers li {
  display: inline-block;
  margin-right: 10px;
}
.paging .page-numbers li:last-child {
  margin-right: 0;
}
.paging .page-numbers li span, .paging .page-numbers li a {
  background: #fff;
  display: inline-block;
  padding: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
  text-decoration: none;
  transition: background 400ms;
  border-radius: 5px;
}
.paging .page-numbers li span.current, .paging .page-numbers li span:hover, .paging .page-numbers li a.current, .paging .page-numbers li a:hover {
  color: #fff;
}
.theme-color-1 .paging .page-numbers li span.current, .theme-color-1 .paging .page-numbers li span:hover, .theme-color-1 .paging .page-numbers li a.current, .theme-color-1 .paging .page-numbers li a:hover {
  background-color: #FBBA00;
}
.theme-color-2 .paging .page-numbers li span.current, .theme-color-2 .paging .page-numbers li span:hover, .theme-color-2 .paging .page-numbers li a.current, .theme-color-2 .paging .page-numbers li a:hover {
  background-color: #006EB7;
}
.theme-color-3 .paging .page-numbers li span.current, .theme-color-3 .paging .page-numbers li span:hover, .theme-color-3 .paging .page-numbers li a.current, .theme-color-3 .paging .page-numbers li a:hover {
  background-color: #95C11F;
}
.theme-color-4 .paging .page-numbers li span.current, .theme-color-4 .paging .page-numbers li span:hover, .theme-color-4 .paging .page-numbers li a.current, .theme-color-4 .paging .page-numbers li a:hover {
  background-color: #006EB7;
}
.paging .page-numbers li span.next, .paging .page-numbers li span.prev, .paging .page-numbers li a.next, .paging .page-numbers li a.prev {
  width: auto;
}
.paging.center .wrapper {
  text-align: center;
}
.paging.center .wrapper .page-numbers {
  margin: 0 auto;
  display: inline-block;
}
.paging.right .wrapper .page-numbers {
  float: right;
}

.qoute .wrapper {
  text-align: center;
}
.qoute .wrapper span {
  font-size: 2rem;
  display: inline-block;
}
.theme-color-1 .qoute .wrapper span {
  color: #006EB7;
}
.theme-color-2 .qoute .wrapper span {
  color: #FBBA00;
}
.theme-color-3 .qoute .wrapper span {
  color: #E84E0F;
}
.theme-color-4 .qoute .wrapper span {
  color: #95C11F;
}
.qoute .wrapper p {
  font-size: 2rem;
  line-height: 1;
  display: inline-block;
}
.theme-color-1 .qoute .wrapper p {
  color: #006EB7;
}
.theme-color-2 .qoute .wrapper p {
  color: #FBBA00;
}
.theme-color-3 .qoute .wrapper p {
  color: #E84E0F;
}
.theme-color-4 .qoute .wrapper p {
  color: #95C11F;
}
.theme-color-1 .qoute .wrapper .name {
  color: #006EB7;
}
.theme-color-2 .qoute .wrapper .name {
  color: #FBBA00;
}
.theme-color-3 .qoute .wrapper .name {
  color: #E84E0F;
}
.theme-color-4 .qoute .wrapper .name {
  color: #95C11F;
}
.theme-color-1 .qoute.dark {
  background: #006EB7;
}
.theme-color-2 .qoute.dark {
  background: #FBBA00;
}
.theme-color-3 .qoute.dark {
  background: #E84E0F;
}
.theme-color-4 .qoute.dark {
  background: #95C11F;
}
.qoute.dark .wrapper span, .qoute.dark .wrapper p, .qoute.dark .wrapper .name {
  color: #fff;
}

.panoramas .wrapper ul li a .popupcontent {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  left: 50px;
  right: 50px;
  top: 50px;
  bottom: 50px;
  max-height: 640px;
  height: 100%;
  padding: 20px;
  border-radius: 15px;
  z-index: 100000;
}
.panoramas .wrapper ul li a .popupcontent.active {
  opacity: 1;
  pointer-events: all;
}
.panoramas .wrapper ul li a .popupcontent .close {
  position: absolute;
  z-index: 200000;
  right: -20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  color: #fff;
  top: -20px;
  border-radius: 100%;
}

/*
 * Helper classes
 */
.box-shadow {
  box-shadow: 0px 5px 8px 1px #d8d8d8;
}

.box-shadow-dark, .off-canvas .search {
  box-shadow: 0px 2px 8px 5px rgba(17, 17, 17, 0.2);
}

.text-shadow {
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centered-horizontal {
  margin: 0 auto;
  text-align: center;
  display: block;
}

.centered-vertical {
  display: flex;
  justify-content: center;
}

.centered, #top-bar a span.button, #navigation ul, #navigation a {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.background-image {
  background-size: cover;
  background-position: center;
}
.background-image.left-top {
  background-position: left top;
}
.background-image.left-center {
  background-position: left center;
}
.background-image.left-bottom {
  background-position: left bottom;
}
.background-image.center-top {
  background-position: center top;
}
.background-image.center-bottom {
  background-position: center bottom;
}
.background-image.right-top {
  background-position: right top;
}
.background-image.right-center {
  background-position: right center;
}
.background-image.right-bottom {
  background-position: right bottom;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.overlay.less {
  background: rgba(0, 0, 0, 0.2);
}

.reset, #navigation ul, .off-canvas.nav_1 ul, .off-canvas.nav_2 .wrapper ul, #breadcrumbs ul, #footer ul, .button-group, #header.header_1 .newsblock ul, .downloads .box ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.embed {
  position: relative;
  overflow: hidden;
  padding-top: -56.25%;
}
.embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.popup {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
  display: none;
}
.popup .close {
  position: absolute;
  right: 40px;
  top: 40px;
  padding: 20px;
  border-radius: 100%;
  background: #fff;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.popup .close span {
  font-size: 1.5rem;
}
.popup.active {
  display: flex;
}

img.alignleft {
  float: left;
  margin-right: 20px;
  margin-top: 10px;
}
img.alignright {
  float: right;
  margin-left: 20px;
  margin-top: 10px;
}

.dark h4, .dark h5, .dark h6 {
  color: #fff;
}