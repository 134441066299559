/* 
 * Reset HTML elements and layout
 */

::selection {
  color: $selection-color;
  background: $selection-bg;
}

*,
input[type="search"] {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html {
  font-size: $font-size-min;

  @include respond-above(md) {
    font-size: calc(($font-size-min + $font-size-max) / 2);
  }

  @include respond-above(lg) {
    font-size: $font-size-max;
  }
}

body {
  background: $body-bg;
  color: $body-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  line-height: $line-height;
  margin: 0;
}

a {
  color: $link-color;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
}
li {
  font-size: $font-size-lg;
  line-height: 1.8;
}
p {
  margin-bottom: 20px;
  margin-top: 0;
  font-size: $font-size-lg;
  line-height: 1.8;

  &:last-child {
    margin-bottom: 0;
  }
}

b,
strong {
  font-weight: $font-weight-bold;
}

embed,
img,
object,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

iframe {
  display: block;
  max-width: 100%; 
}

table {
  @include respond-below(lg){
    display: block;
    overflow: scroll;
    width: 100% !important;

    td {
      min-width: 200px;
      width: auto !important;
    }
  }
}