.panoramas {
    .wrapper {
        ul {
            li {
                a {
                    .popupcontent {
                        opacity: 0;
                        pointer-events: none;
                        position: fixed;
                        background: $white;
                        box-shadow: 0px 0px 10px 0px rgba($black, 0.5);
                        left: 50px;
                        right: 50px;
                        top: 50px;
                        bottom: 50px;
                        max-height: 640px;
                        height: 100%;
                        padding: 20px;
                        border-radius: 15px;
                        z-index: 100000;

                        &.active {
                            opacity: 1;
                            pointer-events: all;
                        }

                        .close {
                            position: absolute;
                            z-index: 200000;
                            right: -20px;
                            width: 40px;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: $black;
                            color: $white;
                            top: -20px;
                            border-radius: 100%;
                        }
                    }
                }
            }
        }
    }
}