#header {
   height: 100vh;

   .wrapper {
      height: 100%;
      z-index: 2;

      .content {
         height: 100%;
         flex-direction: column;

         h1, h2 {
            color: $white;
            text-shadow: 2px 2px rgba(37, 41, 50, 0.5);
         }

         @include respond-below(md){
            h1 {
               font-size: 2rem;
            }
            h2 {
               font-size: 1.5rem;
            }
            a {
               margin-bottom: 10px;
            }
         }

         h2 {
            margin-top: 10px;
         }
      }
   }

   &.header_1 {
      &::after {
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         width: 100%;
         pointer-events: none;
         background: url(../images/blue_1.png) no-repeat;
         background-size: 100% 100%;
         background-position: right top;

         .theme-color-2 & {
            background-image: url(../images/yellow_1.png);
         }
         .theme-color-3 & {
            background-image: url(../images/orange_1.png);
         }
         .theme-color-4 & {
            background-image: url(../images/green_1.png);
         }
      }

      .newsblock {
         position: absolute;
         right: 40px;
         bottom: 100px;
         width: 360px;

         h3 {
            color: $white;
         }

         ul {
            @extend .reset;

            li {
               display: inline-block;
               list-style: none;
               background: $white;
               box-shadow: 0 1px 10px 2px rgba(0,0,0,0.4);
               border-radius: 16px;
               overflow: hidden;

               >div {
                  height: 200px;
                  background-size: cover;
               }

               .content {
                  height: auto;
                  padding: 20px;

                  h4 {
                     margin: 0 0 10px 0;
                  }

                  p {
                     color: $grey;
                  }
               }
            }
         }

         @include respond-below(md){
            display: none;
         }
      }
   }

   &.header_2 {
      z-index: 101;
      padding: 0;
      min-height: 100vh;
      height: auto;

      .top-half {
         width: 100%;
         height: 50vh;

         .button {
            position: absolute;
            bottom: 40px;
            background: $green;
         }

         .scroll {
            left: 40px;
            bottom: -15px;
            border-radius: 100%;
            padding: 8px 5px;
            height: 30px;
            width: 30px;
            @include themify($themes) {
               background: themed('secondary-color');
            }

            span {
               &::before {
                  font-size: 0.5rem;
               }
            }

            @include respond-below(md){
               left: 20px;
            }
         }
      }

      .bottom-half {
         width: 100%;
         height: 50vh;
         position: relative;
         padding: 40px 0;

         &::after {
            content: '';
            background: url(../images/stev_mark.png) no-repeat;
            position: absolute;
            right: -200px;
            top: -200px;
            width: 529px;
            height: 513px;
            opacity: 0.5;

            @include respond-below(md){
               display: none;
            }
         }

         @include respond-below(md){
            height: auto;

            &::after {
               display: none;
            }
         }

         .wrapper {
            @include grid(1,2,2,2,2, 80px);

            .content {
               h1 {
                  margin-top: 0;
                  line-height: 1;
                  margin-bottom: 10px;
               }

               h1, h2 {
                  color: $body-color;
                  text-shadow: none;
                  margin-top: 0;
               }

               p {
                  color: $black;
               }
            }

            .youtube {
               display: flex;
               align-items: flex-start;

               .placeholder {
                  border-radius: 10px;
                  overflow: hidden;
                  position: relative;

                  &::before {
                     position: absolute;
                     top: 0;
                     left: 0;
                     width: 100%;
                     height: 100%;
                     content: '';
                     opacity: 0.65;
                     @include themify($themes) {
                        background: themed('primary-color');
                     }
                  }

                  &::after {
                     width: 60px;
                     height: 35px;
                     content: '\e915';
                     font-family: 'icomoon';
                     background: $white;
                     position: absolute;
                     left: 50%;
                     top: 50%;
                     border-radius: 5px;
                     transform: translate(-50%, -50%);
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     font-size: 2.5rem;
                     @include themify($themes) {
                        color: themed('primary-color');
                     }
                  }
               }
            }
         }
      }
   }

   &.header_3 {
      &::after {
         content: '';
         position: absolute;
         bottom: 0;
         right: 0;
         height: 80%;
         width: 60%;
         pointer-events: none;
         background: url(../images/blue_2.png) no-repeat;
         background-size: 100% 100%;
         background-position: right top;

         .theme-color-2 & {
            background-image: url(../images/yellow_2.png);
         }
         .theme-color-3 & {
            background-image: url(../images/orange_2.png);
         }
         .theme-color-4 & {
            background-image: url(../images/green_2.png);
         }
      }

      @include respond-below(md){
         .content {
            > div {
               padding-top: 40px;
            }
         }
      }

      .wrapper {
         .extra {
            position: absolute;
            bottom: 0;
         }
      }
   }

   .scroll {
      color: $white;
      position: absolute;
      z-index: 5;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      font-weight: $font-weight-bold;
      font-size: 0.875rem;

      span {
         &::before {
            margin-top: 5px;
            color: $white;
            display: block;
            font-size: 1.75rem;
         }
      }

      @include respond-below(md){
         bottom: 10px;
      }
   }

}