.cta-bar {
   background-size: cover;
   background-position: center; 
   padding: 200px 15px;
   position: relative;

   .wrapper {
      .content {
         position: relative;
         z-index: 3;
         text-align: center;
         max-width: 750px;
         margin: 0 auto;
         width: 100%;

         .button {
            min-width: 250px;
         }

         p {
            display: block;
            margin: 20px 0 35px 0;
            color: $white;
         }

         h3 {
            font-size: 3rem;
            margin: 0 !important;
            padding: 0 !important;
            color: $white;
         }
      }
   }
}