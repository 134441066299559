#footer {
    background: $teint;
    margin-top: auto;

    ul {
        @extend .reset;
        @include grid(1,2,2,4,4);
        padding: 20px 0 40px 0 !important;

        li {
            p {
                color: $white; 
                font-size: 1rem;
            }   

            ul {
                display: block;
                padding: 0 !important;
                li {
                    width: 100%;
                    line-height: 1.4;
                    display: block;

                    a {
                        font-size: 1rem;
                        margin-right: 0;
                    }
                }
            }

            h4 {
                color: $white;
                margin-bottom: 10px;
            }

            a {
                text-decoration: none;
                margin-right: 20px;
                color: $white;
                    
                span {
                    &:before {
                        color: $white;
                    }
                }

                &:hover {
                    @include themify($themes) {
                        color: themed('secondary-color');
                    }
                }
            }
        }
    }
    .credits {
        background: $grey-darker;
        color: $white;
        text-align: center;
        padding: 10px 0;
        font-size: 0.875rem;
        font-weight: $font-weight-bold;
    }
}