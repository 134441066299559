/* 
 * Layout base
 */

body {
  width: 100%;
  overflow-x: hidden;

  &.fixed {
    max-height: 100vh;
    overflow: hidden;
  }
}

.layout-container {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  margin: 0;
  transition: transform 400ms;

  &.slide-canvas {
    @include respond-below (lg) {
      position: fixed;
      transform: translate(400px);
    }

    @include respond-below(md) {
      transform: translate(calc(100% - 60px));
    }
  }
}

section {
  position: relative;
  padding: 40px 0;
  overflow: hidden;

  &.pop-up {
    z-index: 999999 !important;
  }

  @include respond-below(md) { 
    padding: 20px 0;
  }
}

.wrapper {
    width: 100%;
    max-width: 1260px;
    padding: 0 40px;
    margin: 0 auto;
    position: relative;
    display: block;

    @include respond-below(xl) {
      padding: 0 40px;
      max-width: 1100px; 
    }

    @include respond-below(lg) {
      padding: 0 40px;
      max-width: 900px;
    }
    
    @include respond-below(md) {
      padding: 0 20px;
    }
}
