.quicklinks {
    padding: 40px 0 80px 0;

    .wrapper {
        h3 {
            font-size: 2.5rem;
        }

        ul {
            margin: 0 auto;
        }
    }

    &.dark {
        background: $blue-dark;
    }

    &.panoramas {
        ul {
            flex-wrap: wrap;
        }
    }
}