#top-bar {
    background: $teint;
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 102;
    position: fixed;
    width: 100%;

    a {
        color: $white;
        text-decoration: none;
        font-size: 0.875rem;
        font-weight: $font-weight-bold;
        margin: 0 10px;
        display: inline-flex;
        align-items: center;
        
        span.button {
            margin-right: 10px;
            margin-left: 20px;
            @extend .centered;

            &::before {
                color: $white;
                font-size: 14px;
            }

            &.icon-Mail::before { 
                font-size: 10px;
            }

            @include themify($themes) {
                background: themed('primary-color');
            }
        }

        &:hover {
            @include themify($themes) {
                color: themed('secondary-color');

                span.button {
                    background: themed('secondary-color');
                }
            }
        }
    }

    &.on-top {
        z-index: 500;
    }

    @include respond-below(md){
        z-index: 99999;
        justify-content: center;

        a {
            span {
                display: none;

                &.button {
                    display: block;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}
