.gallery {
   h1 {
      margin-bottom: 20px;
   }

   &.gallery__1 {
      .wrapper {
         .slick-slider {
            position: relative;
         }

         ul {
            overflow: hidden;
            padding: 0;
            margin: 0;

            li {
               list-style: none; 
               display: inline-block;
               vertical-align: top;
            }
         }
      }

      .slider-btn {
         @include themify($themes) {
            background: themed('primary-color');
         }
         display: inline-block;
         width: 60px;
         height: 60px;
         border-radius: 100px;
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         z-index: 3; 
         text-align: center;
         cursor: pointer;
         border: 2px solid $blue-dark;
         
         @include themify($themes) {
            border-color: themed('primary-color'); 
         }

         transition: all 350ms ease;

         &.prev-slider {
            left: 10px;
         }

         &.next-slider {
            right: 10px;
         }

         span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            font-weight: bold;

            &:before {
               color: $white; 
               transition: all 350ms ease;
            }
         }

         &:hover {
            background: none;

            span {
               &:before {
                  @include themify($themes) {
                     color: themed('primary-color');
                  }
               }
            }
         }

         &.slick-disabled {
            opacity: 0.1;
            pointer-events: none;
            cursor: default;
         }
      }
   }

   &.gallery__2 {
      .wrapper {
         position: relative;
 
         ul {
            padding: 0;
            @include grid(1,2,2,4,4,20px); 

            li {
               list-style: none;
               display: inline-block;
               
               img {
                  max-width: 100%;
               }
            }
         }
      }
   }

   &.dark {
      background: $teint;

      .wrapper {
         h1 {
            color: $white;
         }
      }
   }

   &.special {
      @include themify($themes) {
         background: themed('primary-color');
      }

      h1 {
         color: $white;
      }
   }
}