.text-block {
   padding: 80px 0;

   &.text-block-1,
   &.text-block-2 {
      img, .video {
         display: inline-block;
         width: calc(40% - 20px);
         margin-right: 30px;
         vertical-align: top;
      }

      .text {
         display: inline-block;
         width: calc(60% - 20px);
         vertical-align: top; 

         h1 {
            margin: 0 0 20px 0;
            padding: 0;
         }

         p {
            font-size: 18px;
         }
      }

      @include respond-below(lg){
         img {
            width: 100%;
            display: block;
            margin-bottom: 20px;
            margin-right: 0;
         }

         .text {
            display: block;
            width: 100%;
         }
      }
   }

   &.text-block-2 {
      img, .video {
         margin-right: 0px;
      }

      .text {
         margin-right: 30px; 
      }
   }

   &.text-block-3 {
      padding-top: 0;

      .image {
         position: relative;
         width: 110%;
         transform: translateX(-5%);
         height: 350px;
         background-size: cover;
         background-position: center; 
         margin: 0px 0 40px 0;
         border-bottom-right-radius: 50% 40%;
         border-bottom-left-radius: 50% 40%;
         color: white;
         text-align: center;
         text-indent: .1em;

         @include respond-below(xl){
            border-bottom-right-radius: 50% 70%;
            border-bottom-left-radius: 50% 70%;
         }

         @include respond-below(md){
            border-bottom-right-radius: 60% 50%;
            border-bottom-left-radius: 60% 50%;
         }
      }  

      .text {
         text-align: center; 
      }
   }

   &.dark {
      @include themify($themes) {
         background: themed('primary-color');
      }

      .text {
         li, p, h1, h2, h3, h4, h5, h6, blockquote, div, td, label {
            color: $white;
         }
      }
   }

   @include respond-below(md){
      overflow: hidden;
      padding: 20px 0;

      .video {
         width: 100%;
      }
   }
}