.downloads {
    padding: 40px 0;

    .box {
        border-radius: 20px;
        padding: 20px;

        @include themify($themes) {
            background: themed('primary-color');
        }

        h3 {
            color: $white;
            font-weight: 2.5rem;
            margin-top: 0;
        }

        p {
            color: $white;
        }

        ul {
            @extend .reset;

            li {
                a {
                    color: $white;
                    text-decoration: none;
                    font-weight: $font-weight-bold;

                    &::before {
                        content: '\ea03';
                        font-family: 'Nucleo';
                        margin-right: 10px;
                        display: inline-block;
                        transform: translateY(2px);   
                        @include themify($themes) {
                            color: themed('button-bg');
                        }                 
                    }
                }
            }
        }
    }
    

    &.dark {
        @include themify($themes) {
            background: themed('primary-color');
        }

        .box {
            background: $white;

            h3 {
                font-weight: 2.5rem;
                color: $body-color;
            }

            p {
                color: $body-color;
            }

            a {
                color: $body-color;
            }
        }
    }
}