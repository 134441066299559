/*
 * Helper classes
 */ 

// Simple box-shadow
.box-shadow {
    box-shadow: 0px 5px 8px 1px rgba($grey-lighter, 1);
}
.box-shadow-dark {
    box-shadow: 0px 2px 8px 5px rgba($grey-darkest, 0.2);
}
  
// Simple text-shadow
.text-shadow {
    text-shadow: 0px 3px 6px rgba(0,0,0,0.3);
}
  
// Truncate strings
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Center text and elements horizontal
.centered-horizontal {
    margin: 0 auto;
    text-align: center;
    display: block;
}

// Center horizontal
.centered-vertical {
    display: flex;
    justify-content: center;
}

// Center text and elements vertically
.centered {
    display: flex !important;
    align-items: center;
    justify-content: center;
}
  
// Background image
.background-image {
    background-size: cover;
    background-position: center;

    &.left-top{ background-position: left top; }
    &.left-center{ background-position: left center; }
    &.left-bottom{ background-position: left bottom; }
    &.center-top{ background-position: center top; }
    &.center-bottom{ background-position: center bottom; }
    &.right-top{ background-position: right top; }
    &.right-center{ background-position: right center; }
    &.right-bottom{ background-position: right bottom; }
    
}
  
// Overlay (zwart)
.overlay {
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1;

    &.less {
        background: rgba(0,0,0,0.2);
    }
}
  
// Reset lists
.reset {
    margin: 0;
    padding: 0;
    list-style: none;
}
  
// Embedded content
.embed {
    position: relative;
    overflow: hidden;
    padding-top: -56.25%;
  
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
}  

// Popup
.popup {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba($black, 0.75);
    align-items: center;
    justify-content: center;
    display: none;

    .close {
        position: absolute;
        right: 40px;
        top: 40px;
        padding: 20px;
        border-radius: 100%;
        background: $white;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;   
        text-decoration: none; 

        span {
            font-size: 1.5rem;
        }
    }

    &.active {
        display: flex; 
    }
}

img {
    &.alignleft {
        float: left;
        margin-right: 20px;
        margin-top: 10px;
    }

    &.alignright {
        float: right;
        margin-left: 20px;
        margin-top: 10px;
    }
}

.dark {
    h4, h5, h6 {
        color: $white;
    }
}