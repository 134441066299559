#breadcrumbs {
    padding: 5px 0;
    background: $teint;

    ul {
        @extend .reset;

        li {
            display: inline-block;

            a {
                color: $white;
                text-decoration: none;
                font-size: 1rem;
            }
            span {
                margin: 0 10px;
                &::before {
                    font-size: 0.75rem;
                    @include themify($themes) {
                        color: themed('secondary-color');
                    }
                }
            }
        }
    }
}