/* 
 * Headings
 */

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-heading;
  font-weight: $font-weight-bold;
  line-height: 1.5;
  
  @include themify($themes) {
    color: themed('primary-color');
  }
}
  
h1 {
  font-size: 3rem;
  margin-bottom: 0;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}