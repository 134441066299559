.hero {
   background-size: cover;
   background-position: center;
   position: relative;
   height: 550px;
   padding: 0 20px; 

   .wrapper {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h1 {  
         padding: 0;
         margin: 0;
         color: $white;
         z-index: 200;
         width: 100%;
      }
      h2 {  
         padding: 0;
         margin: 0;
         color: $white;
         z-index: 200;
         width: 100%;
      }
   }

   @include respond-below(md){
      height: 280px;
      padding: 0 0 20px 0;

      .wrapper {
         justify-content: flex-end;

         h1 {
            font-size: 2rem;
         }

         h2 {
            font-size: 1.5rem;
         }
      }
   }
}