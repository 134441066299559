

/* 
 * All colors used on the website.
 */

$white:               #fff;
$black:               #000000;
$off-white:           #f8f8f8;

$blue-dark:           #1D2C35;
$blue:                #006EB7;
$yellow:              #FBBA00;
$orange:              #E84E0F;
$green:               #95C11F; 

$grey-darkest:        #111111;
$grey-darker:         #1B1E24;
$grey-dark:           #252932;
$grey:                #5D5E5E;
$grey-light:          #787878;
$grey-lighter:        #D8D8D8;
$grey-lightest:       #F7F7F7;

$succes:              #5cb85c;
$warning:             #f0ad4e;
$error:               #d9534f;


// Theme colors
$themes: (
    color-1: (
        primary-color:       $blue,
        secondary-color:     $yellow,
        button-bg:           $yellow,
        button-hover-bg:     $yellow,
        button-color:        $white,
    ),
    color-2: (
        primary-color:       $yellow,
        secondary-color:     $blue,
        button-bg:           $blue,
        button-hover-bg:     $blue,
        button-color:        $white,
    ),
    color-3: (
        primary-color:       $orange,
        secondary-color:     $green,
        button-bg:           $green,
        button-hover-bg:     $green,
        button-color:        $white,
    ),
    color-4: (
        primary-color:       $green,
        secondary-color:     $blue,
        button-bg:           $blue,
        button-hover-bg:     $blue,
        button-color:        $white,
    ),
);

$body-bg:             $white;
$body-color:          $grey-darkest;
$selection-bg:        $black;
$selection-color:     $white;
$link-color:          $black; 
$link-hover-color:    $grey-dark;
$heading-color:       $black;

$button-bg:           $black;
$button-hover-bg:     $black;
$button-color:        $white;

$teint:               $blue-dark;