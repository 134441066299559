/*
 * WP paging styling
 */

 .paging {
    .page-numbers {
        list-style: none;
        margin: 0;
        padding: 0;
  
        li {
            display: inline-block;
            margin-right: 10px;
  
            &:last-child {
                margin-right: 0;
            }
  
            span, a {
                background: $white;
                display: inline-block;
                padding: 10px;
                width: 50px;
                height: 50px;
                text-align: center;
                text-decoration: none;
                transition: background 400ms;
                border-radius: 5px;
        
                &.current,
                &:hover {
                    @include themify($themes) {
                        background-color: themed('button-bg');
                    }
                    color: $white;
                }
        
                &.next,
                &.prev {
                    width: auto;
                }
            }
        }
    }
  
    &.center {
        .wrapper {
            text-align: center;
    
            .page-numbers {
                margin: 0 auto;
                display: inline-block;
            }
        }
    }
    &.right {
        .wrapper {
            .page-numbers {
                float: right;
            }
        }
    }
}