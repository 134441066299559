#navigation {
    position: fixed;
    top: 70px;
    width: 100%;
    padding: 10px 40px;
    display: flex;
    justify-content: flex-end;
    z-index: 102;
    transition: all 400ms;

    #logo {
        position: absolute;
        transform: translateY(-50%);
        left: 40px;
        z-index: 0;

        img {
           max-width: 260px;
           max-height: 155px;
           position: absolute;
           transition: opacity 400ms;

           &.color {
               opacity: 0;
           }

           .fixed & {
                height: 65px;
                max-width: none;

                @include respond-below(md) {
                    height:45px;
                }
           }
        }

        &.stev {
           &::before {
              content: '';
              position: absolute;
              top: -265px;
              left: -250px;
              width: 529px;
              height: 513px;
              background: url(../images/stev_mark.png) no-repeat;
              background-size: 529px 513;
              z-index: -1;
              opacity: 0.5;
           }
        }

        &.pill {
           &::before {
              content: '';
              position: absolute;
              top: -280px;
              left: -260px;
              border-radius: 200px;
              width: 600px;
              height: 400px;
              transform: rotate(45deg);
              @include themify($themes) {
                 background: themed('primary-color');
              }
              z-index: -1;
              opacity: 0.65;

              @include respond-below(md){
                  display: none;
              }
           }
        }

    }

    &.fixed {
        #logo {
            img {
                max-width: 200px;
            }

            &.stev,
            &.pill {
                &::before {
                    opacity: 0;
                }
            }
        }
    }

    &.on-top {
        #logo {
            &::before {
                opacity: 0;
                transition: opacity 400ms;
            }
        }
    }

    ul {
        @extend .reset;
        @extend .centered;

        li {
            display: inline-block;
            margin-right: 20px;

            a {
                color: $white;
                text-decoration: none;
                font-size: 1.125rem;
            }
        }

        @include respond-below(md){
            .layout-container & {
                display: none !important;
            }
        }
    }

    a {
        margin-left: 20px;
        @extend .centered;
        flex-direction: column;
        font-size: 0.75rem;
        font-weight: $font-weight-bold;

        span::before {
            color: $white;
            font-size: 1.5rem;
        }

        &.hamburger {
            background: $white;
            position: relative;

            span {
                transition: all 400ms;

                @include themify($themes) {
                    background: themed('primary-color');
                }

                height: 2px;
                width: 24px;
                position: absolute;

                &:first-child {
                    transform: translateY(-6px);
                }

                &:last-child {
                    transform: translateY(6px);
                }
            }

            &.active {
                span {
                    opacity: 0;
                    transform-origin: center center;

                    &:first-child {
                        transform: translateY(0) rotate(45deg);
                        opacity: 1;
                    }

                    &:last-child {
                        transform: translateY(0) rotate(-45deg);
                        opacity: 1;
                    }
                }
            }
        }

        &.route {
            @include themify($themes) {
                background: themed('primary-color');
            }
        }

        @include respond-below(md){
            &.route {
                width: 45px;
                height: 45px;
            }
        }

        &.to-home {
            display: inline-block !important;
            margin-left: 0;
        }
    }

    &.on-top {
        z-index: 350;

        a {
            &.route {
                opacity: 0;
            }
        }
    }

    &.fixed {
        &.nav_2:not(.on-top),
        &.nav_1{
            background: $white;
            top: 50px;

            #logo {
                img {
                    opacity: 0;

                    &.color {
                        opacity: 1;
                        transition: opacity 1200ms;
                    }
                }
            }

            .hamburger {
                @include themify($themes) {
                    background: themed('primary-color');
                }

                span {
                    background: $white !important;
                }
            }

            ul {
                li {
                    a {
                        @include themify($themes) {
                            color: themed('primary-color');
                        }
                    }
                }
            }
        }
    }

    &.nav_1 {
        &.on-top {
            a {
                &.hamburger {
                    margin-right: 300px;
                }
            }
        }
    }

    @include respond-below(lg){
        top: 50px;
        padding: 10px 20px;

        #logo {
            left: 20px;

            &.stev {
                &::before {
                    display: none;
                }
            }
        }
    }

    @include respond-below(md){
        padding: 10;
        z-index: 99999;

        #logo {
            left: 10px;

            img {
                height: 45px;
            }
        }

        a.hamburger.big {
            width: 45px;
            height: 45px;
        }
    }
}

.off-canvas {
    position: fixed;
    z-index: -10;
    opacity: 0;
    transition: all 400ms;

    .search {
        border-radius: 25px !important;
        max-width: 300px !important;
        border: 0;
        padding: 12px 20px !important;
        padding-left: 40px !important;
        @extend .box-shadow-dark;
        position: relative;
        background: url(../images/search.png) $white no-repeat !important;
        background-size: 22px !important;
        background-position: 12px 10px !important;
    }

    .maps {
        overflow: hidden;
        display: inline-block;
        border-radius: 10px;

        iframe {
            display: block;
            max-width: 100%;
        }
    }

    &.active {
        z-index: 300;
        opacity: 1;
    }

    .info {
        display: block;
        width: 100%;
        margin-top: 20px;
        color: $white;

        @include respond-below(md){
            padding-bottom: 40px;
        }

        a {
            color: $white;
            width: 100%;
            display: block;
            padding-top: 15px;
            text-decoration: none;
            font-size: 0.875rem;

            span {
                &::before {
                    color: $white;
                    font-size: 0.6rem;
                    margin-right: 5px;
                }
            }

            &.button {
                font-size: 1rem;
                margin-top: 20px;
                display: inline-block;
                width: auto;
            }
        }
    }

    ul {
        li {
            &.dropdown {
                i {
                    font-style: normal;
                    cursor: pointer;

                    &::after {
                        font-size: 0.7rem;
                        margin-left: 20px;
                        color: $white;
                        content: '\e900';
                        font-family: 'icomoon';
                        transform: translateY(-1px);
                        display: inline-block;
                    }
                }

                > ul {
                    display: none;
                    padding: 0;
                    overflow: hidden;

                    li {
                        a {
                            padding-left: 20px;
                            font-size: 1.125rem;
                        }
                    }
                }

                &.open {
                   i {
                        &::after {
                            content: '\e904';
                        }
                    }
                }
            }

            &.active {
                > a {
                    @include themify($themes) {
                        color: themed('secondary-color') !important;
                    }
                }
            }
        }
    }

    &.nav_1 {
        opacity: 1;
        z-index: 300;
        background: $white;
        max-width: 320px;
        height: calc(100vh - 50px);
        overflow: scroll;
        right: 0;
        top: 50px;
        transform: translateX(100%);
        padding: 20px;
        padding-top: 40px;

        .socials {
            display: none;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;

            .right {
                margin-top: auto;
            }

            .info {
                color: $black;

                a {
                    color: $black;

                    span {
                        &::before {
                            color: $black;
                        }
                    }

                    &.button {
                        color: $white;
                    }
                }
            }
        }

        .maps {
            max-width: 100%;
            height: 200px;
        }

        h3 {
            color: $black;
            font-size: 2rem;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        ul {
            @extend .reset;

            li {
                padding: 5px 0;

                a {
                    text-decoration: none;
                }

                &.dropdown {
                    position: relative;

                    i {
                        position: absolute;
                        right: -30px;

                        &::after {
                            color: $black;
                        }
                    }
                }
            }
        }

        &.active {
            transform: translateX(0);
            z-index: 400;
        }
    }

    &.nav_2 {
        width: 100vw;
        height: calc(100vh - 50px);
        top: 50px;
        padding-top: 110px;

        @include themify($themes) {
            background: themed('primary-color');
        }

        .socials {
            position: absolute;
            left: 30px;
            bottom: 30px;

            a {
                text-decoration: none;
                margin-right: 10px;

                span {
                    &::before {
                        color: $white;
                        font-size: 1.25rem;
                    }
                }
            }

            @include respond-below(lg){
                position: relative;
                bottom: auto;
            }

            @include respond-below(md){
                display: none;
            }
        }

        .wrapper {
            @include grid(1,1,2,2,2);
            height: 100%;
            overflow: auto;

            h3 {
                color: $white;
                font-size: 2rem;
                margin-bottom: 10px;
                margin-top: 20px;
            }

            .left {
                padding: 40px 0;
                height: 100%;

                @include respond-below(md){
                    overflow: unset;
                    padding-bottom: 0;
                }
            }

            .right {
                padding-bottom: 40px;

                @include respond-below(md){
                    padding-bottom: 40px;

                    .maps {
                        display: none;
                    }
                }

            }

            ul {
                @extend .reset;

                li {
                    padding: 5px 0;

                    a {
                        text-decoration: none;
                        color: $white;
                    }
                }
            }
        }

        &::after {
            content: '';
            background: url(../images/stev_mark.png) no-repeat;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 390px;
            height: 360px;
            opacity: 0.75;
            z-index: -1;

            @include respond-below(md){
                display: none;
            }
        }
    }
}