.news {

   &.news-overview {
      .wrapper > ul {
         @include grid(1,2,2,3,3);

         li {
            margin: 0 !important;
         }
      }

      &.news__2 {
         .wrapper > ul{
            @include grid(1,2,2,3,3);
         }
      }
   }

   .slick-track {
      display: flex !important;
   }

   li {
      position: relative;
      
      .school-logo {
         position: absolute;
         left: 10px;
         top: 10px; 

         img {
            width: 25px;
            height: 25px;
         }
      }
   }

   &.news__1 {
      position: relative;
      height: auto;

      .wrapper {
         position: relative;
         z-index: 3;
         text-align: center;

         h1 {
            margin-bottom: -80px;
            text-align: left;
            margin-left: 20px;

            @include respond-below(sm){
               margin-bottom: 20px;
            }
         } 

         > ul {
            position: relative;
            overflow: hidden;
            padding: 80px 0px 40px 0px;
            text-align: left;
            margin: 0;

            .slider-btn {
               background: $blue-dark;
               display: inline-block;
               width: 60px;
               height: 60px;
               border-radius: 100px;
               position: absolute;
               top: 0;
               right: 20px; 
               text-align: center;
               cursor: pointer;
               border: 2px solid $blue-dark;
               transition: all 350ms ease;

               &.prev-slider {
                  right: 100px;
               }

               span {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  right: 0;
                  font-weight: bold;

                  &:before {
                     color: $white; 
                     transition: all 350ms ease;
                  }
               }

               &:hover {
                  background: none;

                  span {
                     &:before {
                        color: $blue-dark;
                     }
                  }
               }

               @include respond-below(sm){
                  left: 100px;

                  &.prev-slider {
                     left: 20px;
                  }
               }
            }

            li {
               display: inline-block;
               list-style: none;
               background: $white;
               box-shadow: 0 2px 38px 0 rgba(0,0,0,0.06);
               border-radius: 16px;
               margin: 20px; 
               overflow: hidden;

               > div {
                  height: 200px;
                  background-size: cover;
               }

               .content {
                  height: auto;
                  padding: 20px;

                  h4 {
                     margin: 0 0 10px 0;
                  }

                  p {
                     color: $grey;
                  }
               }
            }
         }
      }

      &::after {
         content: " ";
         display: block;
         width: 100%;
         position: absolute;
         height: 50%;
         @include themify($themes) {
            background-color: themed('primary-color');
         }
         bottom: 0;
         z-index: 1;
         background-image: url('../images/tools_background.png');
         background-size: auto 100%;
      }

      &::before {
         content: " ";
         display: block;
         width: 120%;
         position: absolute;
         height: 200px;
         @include themify($themes) {
            background-color: themed('primary-color');
         }
         transform: rotate(-2deg);
         top: 50%;
         margin-top: -100px;
         z-index: 1;
         left: -10px;
         
      }
   }

   &.news__2 {
      background-image: url('../images/tools_background_lighter.png');
      background-size: auto 100%;
      background-position: center bottom;

      .wrapper {
         text-align: center;

         h1 {
            margin-bottom: 60px;
         }

         .button {
            margin-top: 60px;
         }

         > ul {
            @include grid(1,1,2,2,2,20px);
            padding: 0;
            text-align: left;

            li {
               background-size: cover;
               list-style: none;
               box-shadow: 0 2px 38px 0 rgba(0,0,0,0.23);
               position: relative;
               height: 370px;
               border-radius: 16px;
               overflow: hidden;
               cursor: pointer;

               .school-logo {
                  left: auto;
                  right: 10px;
               }
               .image {
                  height: 100%;
                  background-size: cover;
               }
               .content {
                  position: absolute;
                  width: 80%;
                  color: $white;
                  bottom: 20px;
                  left: 20px;

                  h4 {
                     color: $white;
                     margin: 0 0 10px 0;
                  }

                  p {
                     line-height: 1.5rem;
                  }

                  a {
                     margin-top: 0;
                  }
               }

               &:before {
                  content: " ";
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  height: 100%;
                  width: 100%;

                  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%);
               }
            }
         }
      }
   }

   &.news__3 {
      .wrapper {
         .text {
            width: calc(33% - 20px);
            display: inline-block;
            margin-right: 30px;
            vertical-align: top;

            a:not(.button) {
               text-decoration: none;
               @include themify($themes) {
                  color: themed('primary-color');
               }
            }

            p {
               margin: 30px 0;
               display: block;
               color: $grey;
            }

            span {
               font-size: 20px;
               margin-right: 20px;
            }

            .socials {
               margin-bottom: 20px;

               a {
                  margin-right: 20px;
                  font-size: 20px;
               }
            }
         }

         .news-items {
            width: calc(66% - 20px);
            display: inline-block;
            vertical-align: top;

            > ul {
               position: relative;
               padding: 40px 0px 40px 0px;
               text-align: left;
               @include grid(1,1,2,2,2,20px); 
               margin: 0;
   
               li {
                  display: inline-block;
                  list-style: none;
                  background: $white;
                  box-shadow: 0 2px 38px 0 rgba(0,0,0,0.10);
                  border-radius: 16px;
                  overflow: hidden;
   
                  >div {
                     height: 200px;
                     background-size: cover;
                  }
   
                  .content {
                     height: auto;
                     padding: 20px;
   
                     h4 {
                        margin: 0 0 10px 0;
                     }
   
                     p {
                        color: $grey;
                     }
                  } 

                  @include respond-below(md){
                     &:nth-child(3){
                        display: none;
                     }

                     &:nth-child(4){
                        display: none;
                     }
                  }
               }
            }
         }

         @include respond-below(lg){
            .text,
            .news-items {
               display: block;
               width: 100%;
            }
         }
      }
   }
}