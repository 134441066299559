/* 
 * Typography
 */

@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700');

$font-family-base:    'Nunito', sans-serif;
$font-family-heading: 'Nunito', sans-serif;

$font-weight-light:   300;
$font-weight-regular: 400;
$font-weight-medium:  600;
$font-weight-bold:    700;
$font-weight-black:   900;

$font-size-min:       14px;
$font-size-max:       16px;
$font-size-base:      1rem;
$font-size-lg:        1.25rem;
$font-size-sm:        0.875rem;
$font-size-xs:        0.75rem;

$line-height:         1.5;