/* 
 * Buttons
 */

.button {
    @include button;

    @include themify($themes) {
        background: themed('button-bg');
        color: themed('button-color');
        border: themed('button-bg');

        &:hover {
            background: darken(themed('button-bg'), 10%);
        }
    }

    &.round {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        padding: 0;

        &.small {
            width: 30px;
            height: 30px;
        }

        &.big {
            width: 65px;
            height: 65px;
        }
    }
}

.wpforms-submit {
    border-radius: 4px !important;

    @include themify($themes) {
        background: themed('button-bg');
        color: themed('button-color');
        border: themed('button-bg');
    }
}

.button-group {
    @extend .reset;

    li {
        border-radius: 100%;
        padding: 10px;
        border: 1px solid $yellow;
        display: inline-block;
        width: 180px;
        height: 180px;
        margin-right: 40px;

        a {
            width: 100%;
            height: 100%;
            background: $yellow;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            flex-direction: column;
            color: $white;
            text-align: center;
            font-size: 1rem;
            font-weight: $font-weight-bold;
            padding: 15px;
            line-height: 1.2;

            &::before {
                content: '\e918';
                font-family: 'icomoon';
                color: $white;
                font-size: 2.5rem;
                display: block;
                margin-bottom: 5px;
                font-weight: $font-weight-regular;
            }

            &.inloggen {
                &::before {
                    content: '\ea04';
                    font-family: 'Nucleo';
                }
            }

            &.afbeeldingen {
                &::before {
                    content: '\ea02';
                    font-family: 'Nucleo';
                }
            }

            &.contact {
                &::before {
                    content: '\e907';
                }
            }

            &.samenwerking {
                &::before {
                    content: '\e905';
                }
            }

            &.leren {
                &::before {
                    content: '\e916';
                }
            }

            &.overige {
                &::before {
                    content: '\e91b';
                    font-size: 2rem;
                }
            }
        }

        &:nth-child(2){
            border-color: $green;

            a {
                background: $green;
            }
        }

        &:nth-child(3){
            border-color: $orange;

            a {
                background: $orange;
            }
        }

        &:nth-child(4){
            border-color: $blue;

            a {
                background: $blue;
            }
        }
    }

    @include respond-below(lg){
        max-width: 600px;
        text-align: center;
        margin-left: 40px !important;

        li {
            margin-bottom: 10px;
        }
    }

    @include respond-below(md){
        max-width: 100%;
        margin-left: 20px !important;
        flex-wrap: wrap;

        li {
            width: 120px;
            height: 120px;
            margin-right: 20px;
            overflow: hidden;

            a {
                font-size: 0.75rem;

                &::before {
                    font-size: 1.5rem;
                }

                &.overige::before {
                    font-size: 1rem;
                }
            }
        }
    }
}