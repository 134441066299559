.event {
   padding: 80px 0;

   .button {
      margin-top: 50px;
   }

   &.event__1 {

      h1 {
         margin-bottom: 50px;
      }

      ul {
         @include grid(1,2,2,3,3,20px);
         padding: 0;
         margin: 0;
          
         li {
            display: inline-block;
            list-style: none;

            .event-title {
               display: block;
               width: 100%;
               margin-bottom: 20px;
               height: 70px;

               @include themify($themes) {
                  background: rgba(themed('primary-color'), 0.7);
               }

               .date {
                  display: inline-block;
                  text-align: center;
                  vertical-align: middle;
                  width: 70px;
                  height: 70px;
                  color: $white;
                  box-sizing: border-box;
                  padding-top: 5px;

                  @include themify($themes) {
                     background: themed('primary-color');
                  }

                  b {
                     display: block;
                     margin-bottom:-10px;
                  }
               }

               h4 {
                  display: inline-block; 
                  vertical-align: middle;
                  margin: 0;
                  padding: 0;
                  margin-left: 10px;
                  color: $white;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
               }
            }

            .read-more {
               margin-top: -10px;
               display: block;
               text-decoration: none;
               @include themify($themes) {
                  color: themed('primary-color');
               }

               span {
                  font-size: 15px;
                  font-weight: bold;
               }
            }
         }
      }

      &.dark {
         background: $blue-dark;

         h1 {
            color: $white;
         }

         p {
            color: $white;
         }
      }
   }

   &.event__2 {
      background-image: url('../images/tools_background_lighter.png');
      background-size: auto 100%;
      background-position: center bottom;

      h1 {
         margin-bottom: 50px;
      }

      ul {
         @include grid(1,2,2,3,3,20px);
         padding: 0;
         margin: 0;
          
         li {
            display: inline-block;
            position: relative;
            list-style: none;
            border-radius: 5px;
            @include themify($themes) {
               background: themed('primary-color');
            }
            color: $white;
            padding: 15px;
            padding-left: 90px;

            .date {
               position: absolute;
               top: 15px;
               left: 0;
               background: $blue-dark;
               text-align: center; 
               width: 70px; 
               height: 70px;
               color: $white;
               box-sizing: border-box;
               padding-top: 5px;
               border-radius: 0px 10px 10px 0px;

               b {
                  display: block;
                  margin-bottom:-10px;
               }
            }

            h4 {
               color: $white;
               margin: 0 0 10px 0;
            }

            p {
               line-height: 1.6rem;
            }

            .read-more {
               margin-top: -10px;
               display: block;
               text-decoration: none;
               color: $white;

               span {
                  font-size: 15px;
                  font-weight: bold;

                  &:before {
                     color: $white;
                  }
               }
            }
         }
      }

      &.dark {
         background-color: $blue-dark;
         background-image: url('../images/tools_background_lighter.png');
         background-size: auto 100%;
         background-position: center bottom;

         h1 {
            color: $white;
         }

         p {
            color: $white;
         }
      }
   }
}